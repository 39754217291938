import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
	tab: {
		bg: '#fff',
		_selected: {
			bg: '#498BF4',
			color: '#fff',
		},
	},
	tablist: {
		bg: '#fff',
	},
	tabpanels: {},
	tabpanel: {
		padding: '0px',
	},
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
