import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { searchPartItems } from '@/api/part';
import { ISearchQuery } from '@/types/filter';
import { IPartItem } from '@/types/parts';
import { IPaginatedResponse } from '@/types/response';

export const usePartItems = (
  searchQuery: ISearchQuery,
  enabled?: boolean,
  page?: number,
): UseQueryResult<IPaginatedResponse<IPartItem>> =>
  useQuery(['parts', searchQuery, page], () => searchPartItems(searchQuery, page), {
    enabled: enabled ?? true,
    staleTime: 2000,
    cacheTime: 2000,
  });
