import { Box, Flex, Image as ChakraImg, Skeleton } from '@chakra-ui/react';
import Image from 'rc-image';
import Slider from 'react-slick';
import Carousel from '../Carousel';

interface Props {
  images?: { image_url: string }[];
}

const ImageGallery = ({ images }: Props) => {
  return (
    <Flex className='flex-col'>
      {images ? (
        <Box maxWidth={658} maxHeight={700} className='rounded-lg'>
          <Slider
            dots
            arrows={false}
            slidesToShow={1}
            className='[&>.slick-thumb>li]:w-30 [&>.slick-thumb>li]:h-24 [&>.slick-thumb]:!flex [&>.slick-thumb]:mt-4 [&>.slick-thumb]:gap-2 [&>.slick-thumb]:flex-wrap'
            dotsClass='slick-thumb'
            // eslint-disable-next-line react/no-unstable-nested-components
            customPaging={idx => (
              <Flex maxWidth={680}>
                <ChakraImg
                  key={`${images[idx].image_url}${idx}`}
                  src={images[idx].image_url}
                  minW='125px'
                  h='92px'
                  className='rounded-lg'
                />
              </Flex>
            )}
          >
            {images &&
              images.map((item, idx) => (
                <Image
                  key={item.image_url}
                  width='640px'
                  height='480px'
                  className='rounded-lg cursor-pointer'
                  src={item.image_url}
                  preview={{
                    imageRender: () => <Carousel images={images} initialIdx={idx} />,
                  }}
                />
              ))}
          </Slider>
        </Box>
      ) : (
        <Skeleton className='rounded-lg' w='658px' h='442px' />
      )}
    </Flex>
  );
};

export default ImageGallery;
