import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string } from 'zod';
import { updateUserProfile } from '@/api/auth';
import ControlledInput from '@/components/ControlledInput/ControlledInput';
import ControlledSelect from '@/components/ControlledSelect';
import { LoginStatus, useAuthStore } from '@/store/useAuthStore';
import { IUser } from '@/types/user';

const phoneRegex = new RegExp(/^\d+$/);

const userSchema = object({
  first_name: string().nonempty('Field is Required'),
  last_name: string().nonempty('Field is Required'),
  phone_number: string()
    .nonempty('Field is Required')
    .regex(phoneRegex, 'Please input only numbers. No special characters are allowed.')
    .min(6, 'Phone Number must be more than 6 characters'),
  email: string().email().nonempty('Field is Required'),
  user_address: object({
    address1: string().nonempty('Field is Required'),
    address2: string().nonempty('Field is Required'),
    city: string().nonempty('Field is Required'),
    post_code: string().nonempty('Field is Required'),
    country: string().nonempty('Field is Required'),
    state: string(),
  }),
});

const companySchema = object({
  user_company: object({
    sales_tax_number: string().nonempty('Field is Required'),
    name: string().nonempty('Field is Required'),
    requisition_number: string().nonempty('Field is Required'),
  }),
}).and(userSchema);

const MyAccount = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { authUser, setAuthUser, setLoginStatus } = useAuthStore();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const { control, handleSubmit, reset } = useForm<IUser>({
    resolver: zodResolver(authUser?.user_company ? companySchema : userSchema),
    defaultValues: { ...authUser },
  });

  const { mutate: updateUser } = useMutation(
    ({ id, data }: { id: number; data: IUser }) => updateUserProfile(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getProfile']);
      },
      retry: true,
    },
  );

  const onClickEditUser = () => {
    setIsEditMode(true);
  };

  const onClickUpdate: SubmitHandler<IUser> = async data => {
    if (!authUser) return;
    setIsEditMode(false);
    try {
      await updateUser({
        id: authUser.id,
        data: {
          ...data,
          user_address: {
            address1: data.user_address.address1,
            address2: data.user_address.address2,
            city: data.user_address.city,
            post_code: data.user_address.post_code,
            country: data.user_address.country,
            state: data.user_address.state,
          },
          ...(data.user_company && {
            user_company: {
              sales_tax_number: data.user_company?.sales_tax_number ?? '',
              name: data.user_company?.name ?? '',
              requisition_number: data.user_company?.requisition_number ?? '',
            },
          }),
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onClickDeleteUser = () => {
    setIsDeleteMode(prev => !prev);
  };

  const onClickLogOutUser = () => {
    localStorage.removeItem('auth');
    setAuthUser(null);
    setLoginStatus(LoginStatus.logout);
    navigate('/');
  };

  useEffect(() => {
    if (authUser) {
      reset(authUser);
    }
  }, [authUser, reset, navigate]);

  if (isDeleteMode) {
    return (
      <div>
        <Heading size='lg' className='mb-8'>
          DELETE ACCOUNT
        </Heading>
        <Flex className='flex-col gap-5'>
          <Text className='font-bold text-xl' color='#808080'>
            When you delete your account, all your information will be permanently removed.
          </Text>
          <ControlledInput name='password' label='Password' control={control} />
          <Button>Confirm</Button>
          <Button variant='black' onClick={onClickDeleteUser}>
            Go back
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <Heading size='lg' className='mb-8'>
        USER INFORMATION
      </Heading>
      <Flex className='flex-col' as='form' gap='5' onSubmit={handleSubmit(onClickUpdate)}>
        {!isEditMode && (
          <Text
            as='u'
            className='font-bold text-xl'
            color='#19478D'
            onClick={onClickEditUser}
            cursor='pointer'
          >
            Update information
          </Text>
        )}
        {authUser?.user_company && (
          <>
            <ControlledInput
              name='user_company.sales_tax_number'
              label='Sales Tax Identification Number'
              control={control}
              inputProps={{ isDisabled: !isEditMode }}
            />
            <ControlledInput
              name='user_company.name'
              label='Business Name'
              control={control}
              inputProps={{ isDisabled: !isEditMode }}
            />
            <ControlledInput
              name='user_company.requisition_number'
              label='Requisition Number'
              control={control}
              inputProps={{ isDisabled: !isEditMode }}
            />
          </>
        )}
        <ControlledInput
          name='first_name'
          label='First Name'
          variant='filled'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='last_name'
          label='Last Name'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='phone_number'
          label='Mobile Phone'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='email'
          label='Email'
          control={control}
          inputProps={{ isDisabled: true }}
        />
        <ControlledInput
          name='user_address.address1'
          label='Number & Building'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='user_address.address2'
          label='Street'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='user_address.city'
          label='City'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledInput
          name='user_address.post_code'
          label='Zip - Code'
          control={control}
          inputProps={{ isDisabled: !isEditMode }}
        />
        <ControlledSelect
          name='user_address.country'
          label={authUser?.user_address.country ?? 'Select Country'}
          control={control}
          selectprops={{ isDisabled: !isEditMode }}
        />
        {isEditMode && (
          <Button type='submit' variant='primary'>
            Update
          </Button>
        )}
        <Flex className='flex-col gap-3'>
          <Text
            as='u'
            className='font-bold text-xl mt-10'
            color='#19478D'
            onClick={onClickLogOutUser}
          >
            Logout
          </Text>
          <Text as='u' className='font-bold text-xl' color='#C7C7C7' onClick={onClickDeleteUser}>
            Delete account
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};

export default MyAccount;
