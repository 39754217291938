import { Heading, Text, Box, Flex, Grid, GridItem, Divider } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getOrderDetail } from '@/api/order';
import { useAuthStore } from '@/store/useAuthStore';
import { useParams } from 'react-router-dom';
import OrderDetailItem from '@/components/OrderDetailtem';
import dayjs from 'dayjs';

const OrderDetail = () => {
  const { orderId = '' } = useParams();

  const { authUser } = useAuthStore();
  const {
    data: {
      id,
      order_items,
      delivery_address,
      delivery_fee,
      create_date,
      invoice_email,
      total_price,
    } = {},
    isSuccess,
  } = useQuery(['orders', authUser], () => getOrderDetail(orderId), {});

  const {
    address1,
    address2,
    city,
    state,
    post_code,
    country,
    first_name,
    last_name,
    phone_number,
  } = delivery_address || {};

  return (
    <Flex className='grow flex-col gap-3'>
      <div>
        <Heading size='lg' className='mb-8'>
          ORDER DETAILS
        </Heading>

        <Flex className='flex-col p-7 py-12 rounded-lg bg-white w-full mb-5' gap={4}>
          <Heading size='md'>
            {order_items?.[0].product?.part_name}
            {order_items?.length &&
              order_items?.length > 1 &&
              ` and ${order_items?.length - 1} more`}
          </Heading>
          <Grid
            templateColumns='repeat(2, minmax(auto, 100%))'
            gap={2}
            whiteSpace='nowrap'
            className='font-bold'
          >
            <GridItem>
              <Text>
                Order Number :<span className='font-normal'> {id}</span>
              </Text>
              <Text>Delivery Address :</Text>
              <Text className='font-normal'>{first_name + ' ' + last_name}</Text>
              <Text className='font-normal'>{address2 + ' ' + address1}</Text>
              <Text className='font-normal'>{post_code + ' ' + city}</Text>
              <Text className='font-normal'>{country}</Text>
            </GridItem>
            <GridItem>
              <Text>
                Order Placed :{' '}
                <span className='font-normal'>
                  {create_date && dayjs(create_date).format('DD MMMM, YYYY')}
                </span>
              </Text>
              <Text>Contact information :</Text>
              <Text className='font-normal underline'>{invoice_email}</Text>
              <Text className='font-normal'>{phone_number}</Text>
            </GridItem>
          </Grid>

          <Flex justify='space-between'>
            <Box>
              <Text className='font-bold'>Order Summary : </Text>
              <Text>Subtotal</Text>
              <Text>Shipping</Text>
              <Text>VAT</Text>
            </Box>
            <Box textAlign='right'>
              <div className='w-full h-4' />
              <Text>{total_price} EUR</Text>
              <Text>{Number(delivery_fee)} EUR</Text>
              <Text>
                {Number(order_items?.[0].product?.retail_store?.vat) * Number(total_price)} EUR
              </Text>
            </Box>
          </Flex>
          <Divider orientation='horizontal' className='color-[#707070] border-b-2' />
          <Flex justify='space-between' className='font-bold'>
            <Text>Total (tax included)</Text>
            <Text>{Number(total_price) + Number(delivery_fee)} EUR</Text>
          </Flex>
        </Flex>

        {isSuccess &&
          order_items?.length &&
          order_items.map(item => <OrderDetailItem item={item} key={item.id} />)}
      </div>
    </Flex>
  );
};

export default OrderDetail;
