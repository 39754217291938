import { create } from 'zustand';
import { IPaymentDeliveryInfo, IPaymentUserInfo } from '@/types/user';

interface AuthState {
	paymentUserInfo: IPaymentUserInfo | null;
	setPaymentUserInfo: (user: IPaymentUserInfo) => void;
	paymentDeliveryInfo: IPaymentDeliveryInfo | null;
	setDeliveryUserInfo: (user: IPaymentDeliveryInfo) => void;
}

export const usePaymentStore = create<AuthState>((set) => ({
	paymentUserInfo: null,
	setPaymentUserInfo: (info: IPaymentUserInfo) => set(() => ({ paymentUserInfo: info })),
	paymentDeliveryInfo: null,
	setDeliveryUserInfo: (info: IPaymentDeliveryInfo) => set(() => ({ paymentDeliveryInfo: info })),
}));
