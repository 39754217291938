import { Button, Flex, Icon } from '@chakra-ui/react';
import { ReactComponent as IconNext } from '@/assets/ic-next-btn.svg';
import { ReactComponent as IconPrevious } from '@/assets/ic-prev-btn.svg';
import { ReactNode, useMemo } from 'react';

interface IPagination {
  total?: number;
  offset?: number;
  onChangeOffset: (page: number) => void;
  page_size?: number;
}

const pageToOffset = (page: number, page_size: number) => (page - 1) * page_size;
const offsetToPage = (offset: number, page_size: number) => Math.floor(offset / page_size) + 1;

const Pagination = ({ offset = 0, onChangeOffset, total = 1, page_size = 20 }: IPagination) => {
  const page = useMemo(() => offsetToPage(offset, page_size), [offset]);

  const pageList = useMemo(
    () =>
      Array.from({ length: Math.ceil(total / page_size) }, (_, idx) => idx + 1).slice(
        page - 1,
        page + 4,
      ),
    [total],
  );

  const onClickPage = (page: number) => onChangeOffset(pageToOffset(page, page_size));

  return (
    <Flex p={50} w='full' alignItems='center' justifyContent='center'>
      <Flex>
        <PageButton onClick={() => page > 1 && onClickPage(page - 1)} disabled={page === 1}>
          <Icon as={IconPrevious} boxSize={5} />
        </PageButton>
        {pageList.map(number => (
          <PageButton key={number} onClick={() => onClickPage(number)} active={number === page}>
            {number}
          </PageButton>
        ))}
        <PageButton
          onClick={() => page < total && onClickPage(page + 1)}
          disabled={page === total || pageList.length === 1}
        >
          <Icon as={IconNext} boxSize={4} />
        </PageButton>
      </Flex>
    </Flex>
  );
};

export default Pagination;

interface IPageButton {
  children: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const PageButton = ({ children, active, onClick, disabled = false }: IPageButton) => {
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      rounded='md'
      bg='transparent'
      color='gray.700'
      _hover={{ bg: '#CDE0F4' }}
      onClick={onClick}
      disabled={disabled}
      {...(disabled && { display: 'none' })}
      {...(active && { bg: '#CDE0F4' })}
    >
      {children}
    </Button>
  );
};
