/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { SelectProps } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useController } from 'react-hook-form';
import { BaseProps, FormControl } from '@/components/FormControl/FormControl';
import { COUNTRY } from '@/constants';

interface ControlledSelectProps extends BaseProps {
  label: string;
  selectprops?: SelectProps;
  children?: ReactNode;
}

const ControlledSelect = (props: ControlledSelectProps) => {
  const { name, control, label, ...rest } = props;

  const { field, formState } = useController({
    name,
    control,
  });

  const countryOptions = name.includes('country')
    ? COUNTRY.map(item => ({ value: item, label: item }))
    : [];

  return (
    <FormControl name={name} control={control} label='Country' {...rest}>
      <Select
        {...field}
        isDisabled={rest.selectprops?.isDisabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        size='md'
        useBasicStyles
        chakraStyles={{
          container: (provided: any) => ({
            ...provided,
          }),
          placeholder: (provided: any) => ({ ...provided, fontweight: 'bold', color: '#2F2F2F' }),
        }}
        placeholder={field.value || 'Select Country'}
        bg='black'
        options={countryOptions}
        onChange={({ value }) => field.onChange(value)}
      />
    </FormControl>
  );
};

export default ControlledSelect;
