import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useFilterStore, useLocationFilterStore } from '@/store/useFilterStore';

const SearchBar = () => {
  const navigate = useNavigate();
  const { setQuery, setFilterReset, setOffset } = useFilterStore();
  const { initFilter } = useLocationFilterStore();
  const [keyword, setKeyword] = useState('');

  const onClickSearch = () => {
    setFilterReset(initFilter);
    setOffset(0);
    setQuery(keyword);
    navigate('/products');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setKeyword(event.target.value);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onClickSearch();
    }
    if (keyword === '') return;
  };

  return (
    <Flex h='80px' bg='#91A8C3' justify='center' align='center'>
      <InputGroup className='bg-white rounded-lg h-[52px] z-10' w='1092px'>
        <Input
          className='!h-full rounded-lg'
          placeholder='Search by Part Name, Part Number, Brand, SKU'
          value={keyword}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <InputRightElement width='180px' className='!h-full'>
          <Button
            colorScheme='blue'
            className='rounded-lg !h-full w-full border-none text-white rounded-r-[8px] !rounded-l-none'
            onClick={onClickSearch}
            style={{ fontSize: '20px' }}
          >
            SEARCH
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export default SearchBar;
