import { useNavigate } from 'react-router-dom';
import { Box, Center, Flex, Grid, GridItem, Link, SimpleGrid, Text } from '@chakra-ui/react';
import { ReactComponent as IconContacts } from '@/assets/icon-phone.svg';
import { ReactComponent as IconReturn } from '@/assets/icon-return.svg';
import { ReactComponent as IconWholeSale } from '@/assets/icon-wholesale.svg';
import { ReactComponent as LogoKorea } from '@/assets/logo-korea.svg';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className='text-white'>
      <Flex
        width='100%'
        justify='center'
        bg='var(--gradient-grdient-200, linear-gradient(149deg, #94CFF0 0%, #69B9FD 36.82%, #06F 100%))'
      >
        <SimpleGrid
          maxWidth='1440px'
          minWidth='1024px'
          flex='1 1 1024px'
          columns={3}
          templateColumns='1fr 1fr 1fr'
          className='h-80 text-xl font-extrabold'
          height='260px'
        >
          <Center
            cursor='pointer'
            className='flex flex-col gap-4 text-2xl'
            onClick={() => navigate('/contact')}
          >
            <IconContacts width={40} height={40} />
            CONTACTS
          </Center>
          <Center
            cursor='pointer'
            className='gap-4 text-center flex flex-col text-2xl'
            onClick={() => navigate('/contact')}
          >
            <IconWholeSale width={80} height={40} />
            WHOLESALE <br /> INQUIRY
          </Center>
          <Center
            cursor='pointer'
            className='flex flex-col gap-4 text-center text-2xl'
            onClick={() => navigate('/contact')}
          >
            <IconReturn width={55} height={55} />
            RETURNS &
            <br /> COMPLAINTS
          </Center>
        </SimpleGrid>
      </Flex>
      <Flex
        width='100%'
        justify='center'
        align='center'
        bg='var(--gradient-grdient-400, linear-gradient(128deg, #4B7DCA 0%, #19478D 47.92%, #00317C 130%))'
        height={430}
      >
        <Box className='relative' maxWidth='1440px' minWidth='1024px' flex='1 1 1024px'>
          <Grid templateColumns='1fr 1fr 1fr'>
            <GridItem colSpan={4} className='mb-12'>
              <LogoKorea width={180} height={50} />
            </GridItem>
            <GridItem>
              <Text className='mb-4 text-lg font-bold'>G-Man Auto Parts Corp.</Text>
              <Box className='text-lg'>
                <Text>Venture Center 201, Hoseo University,</Text>
                <Text>BaebanUb, HoseoRo 79 BunGil 20,</Text>
                <Text>Asan, Chungnam (31499) South Korea.</Text>

                <Text>Sales Tax ID number : 895-81-02287</Text>
              </Box>
            </GridItem>
            <GridItem>
              <Text className='mb-4 text-lg font-bold'>Contacts</Text>
              <Box className='text-lg '>
                <Text>+82-10-4457-7177</Text>
                <Text className='mb-4'>gmanautoparts@gmail.com</Text>
                <Link href='/terms' textDecoration='underline'>
                  Terms and Conditions
                </Link>
              </Box>
            </GridItem>
            <GridItem>
              <Text className='mb-4 text-lg font-bold'>Working hours</Text>
              <Box className='text-lg '>
                <Text>Monday – Friday</Text>
                <Text>9:00 ~ 17:00 (CET)</Text>
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
    </div>
  );
};

export default Footer;
