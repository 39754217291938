import { Flex, Heading, Box, Text, Checkbox, Spacer } from '@chakra-ui/react';
import { Control } from 'react-hook-form';
import ControlledInput from '@/components/ControlledInput';
import ControlledSelect from '@/components/ControlledSelect';
import { IPaymentDeliveryInfo } from '@/types/user';

interface Props {
  control: Control<IPaymentDeliveryInfo>;
}

const PaymentDelivery = ({ control }: Props) => {
  return (
    <Flex className='flex-col '>
      <Flex className='flex-col rounded-lg p-[40px] gap-4' bg='#fff'>
        <Heading className='text-center mb-12'>DELIVERY ADDRESS</Heading>
        <Heading size='md'>Information</Heading>
        <Flex gap='20px'>
          <ControlledInput label='First Name' name='address_first_name' control={control} />
          <ControlledInput label='Last Name' name='address_last_name' control={control} />
        </Flex>
        <ControlledInput label='Mobile Phone' name='address_phone_number' control={control} />
        <Checkbox size='lg' fontWeight={700}>
          Yes , I would like to receive special offer news please.
        </Checkbox>
        <ControlledInput label='Invoice Email' name='address_invoice_email' control={control} />
        <Text fontSize='12px'>The payment invoice will be sent to this email address.</Text>

        <Spacer h='40px' />

        <Heading size='md'>Delivery Address</Heading>
        <ControlledInput
          label='Number & Building'
          control={control}
          name='address_number_building'
        />
        <ControlledInput label='Street' control={control} name='address_street' />
        <Flex className='gap-4'>
          <ControlledInput label='City' className='grow' control={control} name='address_city' />
          <ControlledInput
            label='Post Code'
            className='grow'
            control={control}
            name='address_post_code'
          />
        </Flex>
        <ControlledSelect label='Select Country' control={control} name='address_country' />
      </Flex>
      <Flex>
        <Box />
      </Flex>
    </Flex>
  );
};

export default PaymentDelivery;
