import { useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import MyAccount from '@/components/MyAccount';
import MyOrder from '@/components/MyOrder';
import MyPassword from '@/components/MyPassword';
import SideMenuItem from '@/components/SideMenuItem/SideMenuItem';
import { useNavigate, useParams } from 'react-router-dom';
import OrderDetail from '@/components/OrderDetail';

const sideMenu = [
  { key: 'orders', title: 'My orders' },
  { key: 'account', title: 'My account' },
  { key: 'password', title: 'Change your password' },
];

const MyPage = () => {
  const { type, orderId } = useParams();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('orders');

  const onClickSideMenu = (key: string) => {
    setSelectedMenu(key);
    navigate(`/my-page/${key}`);
  };

  const CurrentMyPage = useMemo(() => {
    if (orderId) return <OrderDetail />;
    switch (selectedMenu) {
      case 'orders':
        return <MyOrder />;
      case 'account':
        return <MyAccount />;
      case 'password':
        return <MyPassword />;
      default:
        return <MyOrder />;
    }
  }, [selectedMenu, orderId]);

  useEffect(() => {
    type && setSelectedMenu(type);
  }, []);

  return (
    <Flex bg='#f5f5f5' className='px-24 py-36 h-full w-full flex justify-center'>
      <Flex className={'max-w-[1440px] min-w-[1024px] grow'}>
        <Flex className='flex-col gap-4 mr-40 sticky top-8 h-fit'>
          <h2 className='font-bold text-[22px]'>Account</h2>
          <Box className='flex-col' minW={220}>
            {sideMenu.map(({ title, key }) => (
              <SideMenuItem
                key={key}
                menuKey={key}
                isActive={key === selectedMenu}
                onClickItem={onClickSideMenu}
              >
                {title}
              </SideMenuItem>
            ))}
          </Box>
        </Flex>
        <Flex className='grow flex-col gap-3'>{CurrentMyPage}</Flex>
      </Flex>
    </Flex>
  );
};

export default MyPage;
