/* eslint-disable react/jsx-props-no-spreading */
import { FC, ReactNode } from 'react';
import {
  Input,
  InputProps,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { BaseProps, FormControl } from '@/components/FormControl/FormControl';

export interface ControlledInputProps extends BaseProps {
  /**
   * Chakra InputProps
   */
  inputProps?: InputProps;

  /**
   * The Chakra InputLeftAddon
   * https://chakra-ui.com/docs/components/input#left-and-right-addons
   */
  leftAddon?: ReactNode;

  /**
   * The Chakra InputRightAddon
   * https://chakra-ui.com/docs/components/input#left-and-right-addons
   */
  rightAddon?: ReactNode;

  /**
   * The Chakra InputLeftElement
   * https://chakra-ui.com/docs/components/input#add-elements-inside-input
   */
  leftElement?: ReactNode;

  /**
   * The Chakra InputRightElement
   * https://chakra-ui.com/docs/components/input#add-elements-inside-input
   */
  rightElement?: ReactNode;
}

const ControlledInput: FC<ControlledInputProps> = (props: ControlledInputProps) => {
  const {
    name,
    control,
    label,
    inputProps,
    leftAddon,
    rightAddon,
    leftElement,
    rightElement,
    ...rest
  } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl name={name} control={control} label={label} {...rest}>
      <InputGroup
      // className="border-solid border-black hover:border-black"
      // _hover={{ borderColor: 'black !important' }}
      >
        {typeof leftAddon === 'string' ? <InputLeftAddon>{leftAddon}</InputLeftAddon> : leftAddon}
        {typeof leftElement === 'string' ? (
          <InputLeftElement>{leftElement}</InputLeftElement>
        ) : (
          leftElement
        )}
        <Input
          {...field}
          value={field.value ?? ''}
          h='42px'
          id={name}
          isDisabled={isSubmitting}
          type={name.includes('password') ? 'password' : 'text'}
          {...inputProps}
        />
        {typeof rightElement === 'string' ? (
          <InputRightElement>{rightElement}</InputRightElement>
        ) : (
          rightElement
        )}
        {typeof rightAddon === 'string' ? (
          <InputRightAddon>{rightAddon}</InputRightAddon>
        ) : (
          rightAddon
        )}
      </InputGroup>
    </FormControl>
  );
};

ControlledInput.displayName = 'ControlledInput';

export default ControlledInput;
