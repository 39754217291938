import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
	field: {
		backgroundColor: 'white !important',
		fontWeight: 700,
		border: '1px solid #2F2F2F',
		borderRadius: 'lg',
	},
	addon: {},
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
