import Navigation from '@/components/Navigation/Navigation';
import SearchBar from '@/components/SearchBar/SearchBar';

const Header = () => (
	<>
		<Navigation />
		<SearchBar />
	</>
);

export default Header;
