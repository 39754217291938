import { MouseEvent } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import ProductInfo from '@/components/ProductInfo';
import { IPartItem } from '@/types/parts';

interface Props {
  item: IPartItem;
  onClickAddCart?: (e: MouseEvent<HTMLButtonElement>, id?: number) => void;
}

const ProductItem = ({ item, onClickAddCart }: Props) => {
  return (
    <Box>
      <Flex className='w-full gap-5'>
        <Flex className='w-full justify-between'>
          <ProductInfo item={item} onClickAddCart={onClickAddCart} />
        </Flex>
      </Flex>
      <Box className='px-12'>
        <Divider />
      </Box>
    </Box>
  );
};

export default ProductItem;
