import { Flex, Image, Menu, MenuButton, MenuItem, MenuList, Checkbox, Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  findCategory,
  findLocation,
  findMaker,
  findModel,
  findModification,
  findOption,
} from '@/api/part';
import iconChevronDown from '@/assets/ic-chevron-down.png';
import { useFilterStore, useLocationFilterStore } from '@/store/useFilterStore';
import { FilterEnum, IFilter, IFilterItem, ILocationItem } from '@/types/filter';
import { useEffect } from 'react';

export interface IFilterItemProps {
  placeholder: string;
  type: FilterEnum;
}

const Filter = ({ placeholder, type }: IFilterItemProps) => {
  const {
    searchQuery: { filter },
    setInitFilterLocation,
    setFilter,
    setFilterLocation,
    setOffset,
    setQuery,
    setQueryReset,
  } = useFilterStore();
  const { brand_id, model_id, category_id } = filter;
  const { setInitLocation } = useLocationFilterStore();

  const isSelected =
    type === 'location'
      ? filter[type][filter[type].length - 1].name !== ''
      : filter[type]?.name !== '';

  const { data: makerData } = useQuery(['brand_id'], () => findMaker(), {
    select: data => {
      return data.items;
    },
  });

  const { data: modelData } = useQuery(['models', brand_id.id], () => findModel(brand_id.id), {
    enabled: !!brand_id.id,
    select: data => {
      return data.items;
    },
  });

  const { data: modificationData } = useQuery(
    ['modification', model_id.id],
    () => findModification(brand_id.id, model_id.id),
    {
      enabled: !!brand_id.id && !!model_id.id,
      select: data => {
        return data.items;
      },
    },
  );

  const { data: categoryData } = useQuery(['category'], () => findCategory(), {
    staleTime: Infinity,
    select: data => {
      return data.items;
    },
  });

  const { data: optionData } = useQuery(
    ['options', category_id.id],
    () => findOption(category_id.id),
    {
      enabled: !!category_id.id,
      staleTime: Infinity,
      select: data => {
        return data.items;
      },
    },
  );

  const { data: locationData, status } = useQuery(['location'], () => findLocation(), {
    enabled: true,
    staleTime: Infinity,
  });

  const dataMapper = {
    brand_id: makerData,
    model_id: modelData,
    classification_id: modificationData,
    category_id: categoryData,
    category_option_id: optionData,
    location: locationData,
  };

  useEffect(() => {
    if (status === 'success') {
      setInitLocation(locationData);
      if (filter.location[0].name === 'init') {
        setInitFilterLocation(locationData);
      } else {
        setInitFilterLocation(filter.location);
      }
    }
  }, [locationData]);

  const onClickMenuItem = (item: IFilterItem | ILocationItem) => {
    setQueryReset();
    console.log('item', filter);
    setOffset(0);
    if (type === 'location') {
      setFilterLocation(item);
      return;
    }
    setFilter(type, item as IFilterItem);
  };

  return (
    <Menu matchWidth>
      <MenuButton
        h='42px'
        bg={`${isSelected ? 'white' : 'gray.200'}`}
        className='h-12 w-full pl-4 pr-4 text-left rounded-[12px]'
      >
        <Flex className='items-center justify-between font-bold text-[16px]'>
          {isSelected ? (type === 'location' ? placeholder : filter[type]?.name) : placeholder}
          <Image src={iconChevronDown} boxSize='28px' />
        </Flex>
      </MenuButton>

      {type === 'location' ? (
        <MenuList
          overflow='auto'
          zIndex='30'
          style={{ display: 'flex', flexWrap: 'wrap', padding: '8px 24px', rowGap: '4px' }}
        >
          {dataMapper[type]?.map((item: ILocationItem) => (
            <Checkbox
              key={`${item.name}`}
              onChange={() => onClickMenuItem(item)}
              size='md'
              spacing={6}
              style={{ minWidth: '50%' }}
              colorScheme='green'
              isChecked={!!filter.location.find(loc => loc.name === item.name)}
            >
              <Box className={'break-normal text-[14px]'}>{item.name}</Box>
            </Checkbox>
          ))}
        </MenuList>
      ) : (
        <MenuList overflow='auto' zIndex='30'>
          {dataMapper[type]?.map((item: IFilterItem) => (
            <MenuItem key={`${item.id}${item.name}`} onClick={() => onClickMenuItem(item)}>
              {item.name}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
};

export default Filter;
