import { useEffect, useState } from 'react';
import IconZoomIn from '@/assets/ic-actions-zoom-in.png';
import IconZoomOut from '@/assets/ic-actions-zoom-out.png';
import arrowRight from '@/assets/arrow-right.svg';
import arrowLeft from '@/assets/arrow-left.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styles from './style.module.scss';

interface ISelfCarousel {
  images: { image_url: string }[];
  initialIdx: number;
}

const Carousel = ({ images, initialIdx = 0 }: ISelfCarousel) => {
  const [style, setStyle] = useState({ transform: 'translate(0)' });
  const [currentIdx, setCurrentIdx] = useState(initialIdx);
  const onClickArrowBtn = (type: 'before' | 'after') => {
    type === 'before' ? setCurrentIdx(prev => prev - 1) : setCurrentIdx(prev => prev + 1);
  };

  useEffect(() => {
    setStyle({ transform: `translate(-${currentIdx}00%)` });
  }, [currentIdx]);

  return (
    <div className={styles.carousel}>
      <div className={styles.carouselBtnImgBox}>
        {currentIdx > 0 && (
          <button
            type='button'
            className={styles.leftBtn}
            onClick={() => onClickArrowBtn('before')}
          >
            <img src={arrowLeft} />
          </button>
        )}

        <div className={styles.imgContainer}>
          <div className={styles.flexBox} style={style}>
            {images?.map(image => {
              return (
                <div key={image.image_url} className={styles.imgBox}>
                  <TransformWrapper initialScale={1}>
                    {({ zoomIn, zoomOut }) => (
                      <>
                        <TransformComponent>
                          <img src={image.image_url} className={styles.img} alt='부품 이미지' />
                        </TransformComponent>
                        <div className='tools'>
                          <button onClick={() => zoomIn()}>
                            <img
                              src={IconZoomIn}
                              alt='zoomin-icon'
                              key='zoomIn'
                              className='rc-image-preview-operations-operation rc-image-preview-operations-operation-zoomIn'
                            />
                          </button>
                          <button onClick={() => zoomOut()}>
                            <img
                              src={IconZoomOut}
                              alt='zoomout-icon'
                              key='zoomOut'
                              className='rc-image-preview-operations-operation rc-image-preview-operations-operation-zoomOut rc-image-preview-operations-operation-disabled'
                            />
                          </button>
                        </div>
                      </>
                    )}
                  </TransformWrapper>
                </div>
              );
            })}
          </div>
        </div>

        {currentIdx < images.length - 1 && (
          <button
            type='button'
            className={styles.rightBtn}
            onClick={() => onClickArrowBtn('after')}
          >
            <img src={arrowRight} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
