/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Heading,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string } from 'zod';
import { signInUser, signUpUser } from '@/api/auth';
import ControlledInput from '@/components/ControlledInput/ControlledInput';
import ControlledSelect from '@/components/ControlledSelect/ControlledSelect';
import { useAuthStore } from '@/store/useAuthStore';
import { IUser } from '@/types/user';
import * as Sentry from '@sentry/react';

const defaultValues = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  re_password: '',
  street: '',
  city: '',
  post_code: '',
  user_address: {
    address1: '',
    address2: '',
    city: '',
    post_code: '',
    country: '',
    state: '',
  },
  user_company: {
    sales_tax_number: '',
    name: '',
    requisition_number: '',
  },
};

const phoneRegex = new RegExp(/^\d+$/);

const signUpSchema = object({
  first_name: string().nonempty('Field is Required'),
  last_name: string().nonempty('Field is Required'),
  phone_number: string()
    .nonempty('Field is Required')
    .regex(phoneRegex, 'Please input only numbers. No special characters are allowed.')
    .min(6, 'Phone Number must be more than 6 characters'),
  email: string().email().nonempty('Field is Required'),
  password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
  re_password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
  user_address: object({
    address1: string().nonempty('Field is Required'),
    address2: string().nonempty('Field is Required'),
    city: string().nonempty('Field is Required'),
    post_code: string().nonempty('Field is Required'),
    country: string().nonempty('Field is Required'),
    state: string(),
  }),
}).refine(data => data.password === data.re_password, {
  path: ['re_password'],
  message: 'Password did not match',
});

const companySchema = object({
  user_company: object({
    sales_tax_number: string().nonempty('Field is Required'),
    name: string().nonempty('Field is Required'),
    requisition_number: string().nonempty('Field is Required'),
  }),
}).and(signUpSchema);

const SignUp = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('customer');
  const setAuthUser = useAuthStore(state => state.setAuthUser);

  const { control, handleSubmit } = useForm<IUser>({
    resolver: zodResolver(type === 'customer' ? signUpSchema : companySchema),
    defaultValues,
  });

  const { mutateAsync: signInMutate } = useMutation(
    (data: { email: string; password: string }) => signInUser(data),
    {
      onSuccess: data => {
        if (!data.access) return;
        localStorage.setItem('auth', JSON.stringify(data));
        navigate('/');
      },
    },
  );

  const { mutateAsync: signUpMutate } = useMutation((data: Omit<IUser, 'id'>) => signUpUser(data), {
    onMutate: () => {},
    onSuccess: data => {
      setAuthUser(data);
    },
  });

  const onSubmit: SubmitHandler<IUser> = async data => {
    try {
      await signUpMutate({
        ...data,
        is_send_news: true,
        user_address: {
          address1: data.user_address.address1,
          address2: data.user_address.address2,
          city: data.user_address.city,
          post_code: data.user_address.post_code,
          country: data.user_address.country,
          state: data.user_address.state ?? '',
        },
        user_type: type === 'customer' ? 1 : 2,
        ...(data.user_company && {
          user_company: {
            sales_tax_number: data.user_company?.sales_tax_number ?? '',
            name: data.user_company?.name ?? '',
            requisition_number: data.user_company?.requisition_number ?? '',
          },
        }),
      });
      await signInMutate({ email: data.email, password: data.password });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  return (
    <Box className='pb-14 pt-14 px-36 flex justify-center' bg='#f5f5f5'>
      <div className={'w-[1330px] '}>
        <Heading mb='7' fontSize='20px'>
          CREATE YOUR OWN USERACCOUNT
        </Heading>
        <Flex as='form' className='flex-col' onSubmit={handleSubmit(onSubmit)}>
          <RadioGroup onChange={setType} value={type}>
            <Flex className='flex-col gap-5'>
              <Radio value='customer'>Private Customer</Radio>
              <Radio value='company'>Company</Radio>
            </Flex>
          </RadioGroup>
          {type === 'company' && (
            <div className='mt-20'>
              <Text className='mb-4' fontSize='lg'>
                If you want to order parts as a company, you can now enter your company data
              </Text>
              <SimpleGrid columns={2} spacing={5}>
                <ControlledInput
                  label='Sales Tax Identification Number'
                  control={control}
                  name='user_company.sales_tax_number'
                />
                <ControlledInput label='Business Name' control={control} name='user_company.name' />
                <ControlledInput
                  label='Requisition Number'
                  control={control}
                  name='user_company.requisition_number'
                />
                <Text className='pt-8'>
                  Here you may indicate your own requisition number, which will appear in your
                  invoice, and it will follow the goods and also appear in the shipping papers.
                </Text>
              </SimpleGrid>
            </div>
          )}
          <Flex className='mt-16 gap-7'>
            <ControlledInput label='First Name' name='first_name' control={control} />
            <ControlledInput label='Last Name' name='last_name' control={control} />
          </Flex>
          <Flex className='mt-5 gap-7 [&>*]:flex-1'>
            <ControlledInput
              label='Mobile Phone'
              className='w-1/2'
              name='phone_number'
              control={control}
            />
            <Text className='mt-7 w-1/2' size='md' color='#808080'>
              When entering a number this will be used for sending updates by SMS on delivery of
              your order
            </Text>
          </Flex>
          <Checkbox fontWeight={700} className='mt-5'>
            Yes , I would like to receive special offer news please.
          </Checkbox>
          <Heading mt='20' mb='7' fontSize='20px'>
            LOG-IN INFORMATION
          </Heading>
          <Flex className='flex-col gap-4'>
            <ControlledInput label='Email' control={control} name='email' />
            <ControlledInput label='Create Password' control={control} name='password' />
            <ControlledInput label='Re-Confirm Password' control={control} name='re_password' />
          </Flex>
          <Heading mt='20' mb='7' fontSize='20px'>
            BILLING ADDRESS
          </Heading>
          <Flex className='flex-col gap-5'>
            <ControlledInput
              label='Number & Building'
              control={control}
              name='user_address.address1'
            />
            <ControlledInput label='Street' control={control} name='user_address.address2' />
            <Flex className='gap-6'>
              <ControlledInput
                label='City'
                className='grow'
                control={control}
                name='user_address.city'
              />
              <ControlledInput
                label='Post Code'
                className='grow'
                control={control}
                name='user_address.post_code'
              />
            </Flex>
            <ControlledSelect
              label='Select Country'
              control={control}
              name='user_address.country'
            />
          </Flex>
          <Button className='mt-8 w-full mb-24' h='42px' type='submit' variant='primary'>
            Register as a user to order parts
          </Button>
        </Flex>
      </div>
    </Box>
  );
};

export default SignUp;
