import React from 'react';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ReactComponent as IconDown } from '@/assets/ic-chevron-down-white.svg';

const LanguageValue = [
  { title: 'English', value: 'English' },
  { title: 'Polish', value: 'Polish' },
  { title: 'Russian', value: 'Russian' },
];

const LanguageSelector = () => {
  const onClickItem = () => {};
  return (
    <Box>
      <Menu matchWidth variant='language'>
        <MenuButton borderRadius='md' borderWidth='1px' className='h-12 w-full text-left'>
          <Flex className='items-center justify-between font-semibold items-start'>
            Language
            <IconDown />
          </Flex>
        </MenuButton>
        <MenuList overflow='auto'>
          {LanguageValue.map(item => (
            <MenuItem key={item.value} onClick={() => onClickItem()}>
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default LanguageSelector;
