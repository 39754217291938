import { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  ModalCloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  VStack,
  Spacer,
  ModalOverlay,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FileUploader } from 'react-drag-drop-files';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';
import { ReactComponent as IconAdd } from '@/assets/ic-actions-add.svg';
import ControlledInput from '@/components/ControlledInput';
import { ControlledTextArea } from '@/components/ControlledTextArea/ControlledTextArea';
import { useNavigate } from 'react-router-dom';

interface IFiles extends FileList {
  name: string;
  type: string;
}

const fileTypes = ['JPEG', 'JPG', 'PNG', 'XLSX'];

const defaultValues = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  inquiry: '',
};

const contactSchema = object({
  first_name: string().nonempty('Field is Required'),
  last_name: string().nonempty('Field is Required'),
  phone_number: string().nonempty('Field is Required'),
  email: string().email().nonempty('Field is Required'),
  inquiry: string().nonempty('Field is Required'),
});

type ContactInput = TypeOf<typeof contactSchema>;

const ContactUs = () => {
  const [isSend, setIsSend] = useState(false);
  const [fileLists, setFileLists] = useState<IFiles[]>([]);
  const navigate = useNavigate();

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(contactSchema),
    defaultValues,
  });

  const isValidateUpload = (files: IFiles[]) => {
    const existFiles = Array.from(fileLists);
    if (existFiles.length === 0) return true;
    const countFiles = existFiles.reduce(
      (acc, item) => {
        if (item.type.includes('image')) {
          acc.image += 1;
        } else if (item.type.includes('sheet')) {
          acc.xlsx += 1;
        }
        return acc;
      },
      { image: 0, xlsx: 0 },
    );

    let imageCount = countFiles.image;
    let xlsxCount = countFiles.xlsx;

    const hasDuplicate = Array.from(files).every(file => {
      if (existFiles.some(item => item.name === file.name)) return false;
      if (file.type.includes('image')) imageCount += 1;
      else if (file.type.includes('sheet')) xlsxCount += 1;

      if (imageCount > 19 || xlsxCount > 4) return false;
      return true;
    });
    if (!hasDuplicate) return false;
    if (countFiles.image === 19 || countFiles.xlsx === 4) return false;
    return true;
  };

  const handleChange = (files: IFiles[]) => {
    if (!isValidateUpload(files)) return;
    setFileLists(prev => [...prev, ...files]);
  };

  const onSubmit: SubmitHandler<ContactInput> = data => {
    console.log('contact us form submit:', data);
  };

  const onClickReset = () => {
    setIsSend(false);
    reset();
    setFileLists([]);
    navigate('/');
  };

  return (
    <Box className='w-full' bg='#f5f5f5'>
      <div className='max-w-[1440px] min-w-[1024px] mx-auto py-[100px]'>
        <h2 className='mb-[30px] text-[20px] font-bold'>CONTACT US</h2>
        <Box as='form' onSubmit={handleSubmit(onSubmit)}>
          <Grid gap={4} templateColumns='1fr 1fr'>
            <GridItem colSpan={1}>
              <ControlledInput label='First Name' name='first_name' control={control} />
            </GridItem>
            <GridItem colSpan={1}>
              <ControlledInput label='Last Name' name='last_name' control={control} />
            </GridItem>
            <GridItem colSpan={2}>
              <ControlledInput label='Mobile Phone' name='phone_number' control={control} />
            </GridItem>
            <GridItem colSpan={2}>
              <ControlledInput label='Email' name='email' control={control} />
            </GridItem>
            <GridItem colSpan={2}>
              <ControlledTextArea label='Inquiry' name='inquiry' control={control} />
            </GridItem>
            <GridItem colSpan={2}>
              <FileUploader
                label='Drop Your files here!'
                className='h-full w-full'
                multiple
                types={fileTypes}
                maxSize={20}
                handleChange={handleChange}
              >
                {fileLists.length !== 0 ? (
                  <Box className='h-48 p-11' borderWidth='1px' borderRadius='lg' bg='#fff'>
                    {fileLists.map(item => (
                      <Tag
                        size='md'
                        key={item.name}
                        borderRadius='full'
                        variant='outline'
                        colorScheme='blue'
                        className='mb-2 mr-3 !px-3 py-1'
                      >
                        <TagLabel>{item.name}</TagLabel>
                        <TagCloseButton />
                      </Tag>
                    ))}
                    <Button
                      bg='#000'
                      className='!absolute -bottom-7 left-1/2 !h-16 w-72 -translate-x-2/4'
                      color='#fff'
                      _hover={{ bg: '#2F2F2F' }}
                      leftIcon={<IconAdd />}
                    >
                      Add files
                    </Button>
                  </Box>
                ) : (
                  <Box className='h-48 flex-col border-2 border-dashed border-gray-100 bg-gray-200'>
                    <Center h='full'>
                      <h2 className='text-[18px] text-[#808080] font-bold'>
                        Drop your files here!
                      </h2>
                    </Center>
                    <Text color='#808080' className='absolute bottom-11 left-1/2 -translate-x-2/4'>
                      or click
                    </Text>
                    <Button
                      bg='#000'
                      className='absolute bottom-5 left-1/2 !h-16 w-72 -translate-x-2/4 rounded-xl'
                      _hover={{ bg: '#2F2F2F' }}
                      color='#fff'
                      leftIcon={<IconAdd />}
                    >
                      Add files
                    </Button>
                  </Box>
                )}
              </FileUploader>
            </GridItem>
            <GridItem colSpan={2}>
              <Button
                className='mt-24 w-full h-[42px]'
                colorScheme='blue'
                onClick={() => setIsSend(true)}
              >
                Send
              </Button>
            </GridItem>
          </Grid>
        </Box>
        <Modal isOpen={isSend} onClose={() => setIsSend(false)} size='6xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <Spacer className='mb-14' />
            <VStack>
              <ModalBody className=''>Inquiry Sent.</ModalBody>
              <ModalFooter className='mb-10'>
                <Button variant='primary' w='280px' onClick={onClickReset}>
                  Confirm
                </Button>
              </ModalFooter>
            </VStack>
          </ModalContent>
        </Modal>
      </div>
    </Box>
  );
};

export default ContactUs;
