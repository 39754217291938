import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

interface Props {
	isActive: boolean;
	menuKey: string;
	onClickItem: (key: string) => void;
	children: ReactNode;
}

const SideMenuItem = ({ isActive, menuKey, onClickItem, children }: Props) => {
	return (
		<Flex
			as="button"
			bg={isActive ? '#CDE0F4' : ''}
			fontWeight={isActive ? 'bold' : ''}
			height="45px"
			className="text-lg p-3 items-center"
			onClick={() => onClickItem(menuKey)}
		>
			{children}
		</Flex>
	);
};

export default SideMenuItem;
