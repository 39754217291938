import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Flex,
  Heading,
  Progress,
  Step,
  Image,
  StepIndicator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Text,
  Button,
  StepSeparator,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { object, string } from 'zod';
import { getCartList } from '@/api/cart';
import IconD from '@/assets/ic-step-done.png';
import IconE from '@/assets/ic-step-edit.png';
import CartItem from '@/components/CartItem';
import PaymentDelivery from '@/pages/PaymentDelivery';
import PaymentMethod from '@/pages/PaymentMethod/PaymentMethod';
import PaymentSummary from '@/pages/PaymentSummary';
import { usePaymentStore } from '@/store/usePaymentStore';
import { IPaymentDeliveryInfo } from '@/types/user';
import PaymentDeliverySummary from '../PaymentDeliverySummary/PaymentDeliverySummary';

const steps = [{ title: 'DELIVERY' }, { title: 'PAYMENT METHOD' }, { title: 'ORDER COMPLETE' }];

const defaultAddressValues = {
  address_first_name: '',
  address_last_name: '',
  address_phone_number: '',
  address_invoice_email: '',
  address_number_building: '',
  address_street: '',
  address_city: '',
  address_post_code: '',
  address_country: '',
};

const deliveryInfo = object({
  address_first_name: string().nonempty('Field is Required'),
  address_last_name: string().nonempty('Field is Required'),
  address_phone_number: string().nonempty('Field is Required'),
  address_invoice_email: string().email().nonempty('Field is Required'),
  address_number_building: string().nonempty('Field is Required'),
  address_street: string().nonempty('Field is Required'),
  address_city: string().nonempty('Field is Required'),
  address_post_code: string().nonempty('Field is Required'),
  address_country: string().nonempty('Field is Required'),
});

const Payment = () => {
  const navigate = useNavigate();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });
  const { setDeliveryUserInfo } = usePaymentStore();

  const {
    control: deliveryControl,
    handleSubmit: handleSubmitDelivery,
    formState: { isValid: isDeliveryValid },
  } = useForm<IPaymentDeliveryInfo>({
    resolver: zodResolver(deliveryInfo),
    defaultValues: defaultAddressValues,
  });

  const { data: carts } = useQuery(['carts'], () => getCartList());

  const max = steps.length - 1;
  const progressPercent = (activeStep / max) * 100;

  const onSubmitDelivery = handleSubmitDelivery(data => setDeliveryUserInfo(data));

  const onClickTerms = () => {
    setAgreeTerms(prev => !prev);
  };

  const onClickNextStep = () => {
    if (activeStep === 0) {
      onSubmitDelivery();
      if (isDeliveryValid) {
        activeStep < max && setActiveStep(prev => prev + 1);
      }
      return;
    }
    activeStep < max && setActiveStep(prev => prev + 1);
  };

  return (
    <Box className='pl-24 pr-40 py-36 h-full w-full flex justify-center' bgColor='#f5f5f5'>
      <div className='max-w-[1440px] min-w-[1024px] grow'>
        <Box>
          <Stepper index={activeStep} size='lg' gap={0} className='items-center'>
            {steps.map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Step key={index} className='z-10'>
                <Flex className='flex-col items-center bg-'>
                  <StepIndicator
                    bg='white'
                    className='data-[status=active]:bg-[#3182ce] data-[status=active]:!border-0'
                  >
                    <StepStatus
                      complete={<Image src={IconD} marginTop='3px' />}
                      active={<Image src={IconE} marginTop='3px' />}
                    />
                  </StepIndicator>
                  <StepSeparator />
                  <StepTitle className='mt-4'>{step?.title}</StepTitle>
                </Flex>
              </Step>
            ))}
          </Stepper>
          <Progress
            value={progressPercent}
            className='absolute w-11/12 z-0 !h-2'
            top='-66px'
            bg='#E8E8E8'
            marginLeft='50px'
          />
        </Box>
        <Heading size='lg' className='mt-16 mb-12 text-center'>
          {activeStep !== 2 && steps[activeStep]?.title}
        </Heading>
        {activeStep === 0 && (
          <Alert
            bgColor='#FFE5EA'
            className='rounded-lg mb-8 !p-12 flex-col !items-start text-[#FF000F] font-bold'
          >
            <Box>
              <Text>Note</Text>
              <Text>
                Note. The cross boarder delivery from South Korea, the EU import duty 10% and VAT
                rate on your order will be adjusted accordance to EU import duty and EU VAT rules
                regarding the final destination country. <br />
                The parts stock in EU, paid the EU import duty and 21% VAT. VAT and any duties are
                paid by the customer when the item(s) arrive.
              </Text>
            </Box>
          </Alert>
        )}
        <Flex className='gap-[40px]'>
          <Flex className='flex-col' flex={3}>
            <Flex className='flex-col'>
              {activeStep === 0 && <PaymentDelivery control={deliveryControl} />}
              <Box className='mt-8'>
                {activeStep === 0 &&
                  carts &&
                  carts?.map(({ id, product }) => <CartItem item={product} key={id} />)}
              </Box>
            </Flex>
            {activeStep === 1 && (
              <Flex gap='30px' direction='column'>
                <PaymentDeliverySummary />
                <PaymentMethod onClickTerms={onClickTerms} />
              </Flex>
            )}
            {activeStep === 2 && (
              <Flex className='flex-col items-center gap-12 mt-36'>
                <Heading size='lg'>ORDER COMPLETED!</Heading>
                <Text>
                  We are getting started on your order right away and you will receive an order
                  confirmation email shortly.
                </Text>
                <Button
                  variant='primary'
                  w='378px'
                  className='mt-12'
                  onClick={() => navigate('/my-page/orders')}
                >
                  To check your order
                </Button>
              </Flex>
            )}
          </Flex>
          {activeStep !== 2 && (
            <PaymentSummary
              activeStep={activeStep}
              agreeTerms={agreeTerms}
              onClickNextStep={onClickNextStep}
            />
          )}
        </Flex>
      </div>
    </Box>
  );
};

export default Payment;
