import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading, Link } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { getUserProfile, signInUser } from '@/api/auth';
import ControlledInput from '@/components/ControlledInput/ControlledInput';
import * as Sentry from '@sentry/react';
import { LoginStatus, useAuthStore } from '@/store/useAuthStore';

const defaultValues = {
  email: '',
  password: '',
};

const signInSchema = object({
  email: string().min(1, 'Field is Required').email('Email Address is invalid'),
  password: string().min(1, 'Field is Required').min(4, 'Password must be more than 4 characters'),
});

type LoginInput = TypeOf<typeof signInSchema>;

const SignIn = () => {
  const navigate = useNavigate();
  const { setLoginStatus, setAuthUser } = useAuthStore();
  const { control, handleSubmit, formState } = useForm<LoginInput>({
    resolver: zodResolver(signInSchema),
    defaultValues,
  });

  const { mutate: signInMutate } = useMutation((data: LoginInput) => signInUser(data), {
    onSuccess: async data => {
      if (!data.access) return;
      localStorage.setItem('auth', JSON.stringify(data));
      const res = await getUserProfile();
      setAuthUser(res);
      setLoginStatus(LoginStatus.login);
      navigate('/');
    },
    onError: err => {
      Sentry.captureException(err);
      console.error(err);
    },
  });

  const onSubmit: SubmitHandler<LoginInput> = data => {
    signInMutate(data);
  };

  return (
    <div className='mb-14 mt-28 px-36 w-full flex justify-center'>
      <div className='w-[1330px]'>
        <Heading mb='7' fontSize='20px'>
          PLEASE LOGIN
        </Heading>
        <Flex as='form' className='flex-col' align='stretch' onSubmit={handleSubmit(onSubmit)}>
          <ControlledInput label='Email' name='email' control={control} />
          <ControlledInput label='Password' name='password' control={control} className='mt-5' />
          <Link
            href='/find-pw'
            textDecoration='underline'
            color='gray.400'
            alignSelf='end'
            className='mt-5'
          >
            You lost your password
          </Link>
          <Box display='flex' className='w-full flex-col mt-8 mb-56'>
            <Button className='mb-5 w-full' colorScheme='blue' fontSize='16px' type='submit'>
              Login
            </Button>
            <Link
              href='/sign-up'
              textDecoration='underline'
              color='blue.700'
              fontWeight='bold'
              alignSelf='center'
            >
              Create account
            </Link>
          </Box>
        </Flex>
      </div>
    </div>
  );
};

export default SignIn;
