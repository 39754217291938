import { IOrderParams } from '@/types/user';
import { http } from '@/utils/http';
import { IPaginatedResponse } from '@/types/response.ts';
import { IOrder } from '@/types/cart.ts';

export const getOrderList = (
  offset?: number,
  limit: number = 20,
): Promise<IPaginatedResponse<IOrder>> => {
  return http.get(`/order/order-list?limit=${limit}&offset=${offset}`);
};

export const getOrderDetail = (id: string): Promise<IOrder> => {
  return http.get(`/order/order-list/${id}`);
};

export const postOrder = (orderInfo: IOrderParams) => {
  return http.post('/order/order-list', { ...orderInfo });
};
