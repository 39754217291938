import { FilterEnum, IFilterItem, ILocationItem, ISearchQuery } from '@/types/filter';
import { IPartList, IPartItem, ICrossRef, IFitment } from '@/types/parts';
import { IPaginatedResponse } from '@/types/response';
import { http } from '@/utils/http';

export const searchPartItems = (
  searchQuery: ISearchQuery,
  page: number | undefined = 0,
  limit: number | undefined = 20,
): Promise<IPartList> => {
  const {
    filter,
    sorter: { ordering },
    number,
    query,
  } = searchQuery;
  const filterParams: { [key in FilterEnum]?: number | string } = Object.keys(filter).reduce(
    (acc, cur) => {
      const filterValue = filter[cur as FilterEnum];
      if (Array.isArray(filterValue)) {
        acc[cur as FilterEnum] = filterValue.map(value => value.name).join();
      } else {
        if (filterValue && filterValue.id) {
          acc[cur as FilterEnum] = filterValue.id;
        }
      }
      return acc;
    },
    {} as { [key in FilterEnum]?: number | string },
  );
  return http.get(`/part/product?limit=${limit}&offset=${page}&ordering=${ordering}`, {
    ...filterParams,
    ...number,
    query,
  });
};

export const getPartItem = (id?: string): Promise<IPartItem> => {
  return http.get(`/part/product/${id}`);
};

export const getPartCrossReference = (partNumber: string): Promise<{ items: ICrossRef[] }> => {
  return http.get(`/part/cross-references-part`, { part_number: partNumber });
};

export const findMaker = (): Promise<IPaginatedResponse<IFilterItem>> => {
  return http.get(`/part/brand`);
};

export const findModel = (id: number): Promise<IPaginatedResponse<IFilterItem>> => {
  return http.get(`/part/car-model`, {
    brand_id: id,
    limit: 100,
  });
};

export const findModification = (
  brandId: number,
  modelId: number,
): Promise<IPaginatedResponse<IFilterItem>> => {
  return http.get(`/part/car-classification`, {
    brand_id: brandId,
    model_id: modelId,
    limit: 100,
  });
};

export const findCategory = (): Promise<IPaginatedResponse<IFilterItem>> => {
  return http.get(`/part/part-category`, { limit: 100 });
};

export const findOption = (id: number): Promise<IPaginatedResponse<IFilterItem>> => {
  return http.get(`/part/part-category-option`, { category_id: id, limit: 100 });
};

export const findLocation = (): Promise<ILocationItem[]> => {
  return http.get(`/part/product-location`);
};

export const findFitment = (partNumber: string | number): Promise<{ items: IFitment[] }> => {
  return http.get(`part/fitment`, { part_number: partNumber });
};
