import { usePaymentStore } from '@/store/usePaymentStore';
import { Flex, Heading, Text } from '@chakra-ui/react';

const PaymentDeliverySummary = () => {
  const { paymentDeliveryInfo } = usePaymentStore();

  return (
    <Flex className='flex-col '>
      <Flex className='flex-col rounded-lg p-[40px] gap-4' bg='#fff'>
        <Heading size='md' className='text-center'>
          DELIVERY ADDRESS
        </Heading>
        <Text>
          {paymentDeliveryInfo?.address_first_name} {paymentDeliveryInfo?.address_last_name}
        </Text>
        <Text>{paymentDeliveryInfo?.address_phone_number}</Text>
        <Text>{paymentDeliveryInfo?.address_invoice_email}</Text>
        <Text>
          {paymentDeliveryInfo?.address_number_building} {paymentDeliveryInfo?.address_street}
        </Text>
        <Text>
          {paymentDeliveryInfo?.address_post_code} {paymentDeliveryInfo?.address_city}
        </Text>
        <Text>{paymentDeliveryInfo?.address_country}</Text>
      </Flex>
    </Flex>
  );
};

export default PaymentDeliverySummary;
