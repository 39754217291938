import React from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Radio,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as LogoPayneer } from '@/assets/logo-payoneer.svg';
import Terms from '@/pages/Terms';

interface Props {
  onClickTerms: () => void;
}

const PaymentMethod = ({ onClickTerms }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex className='flex-col rounded-lg p-12 gap-7' bg='white'>
        <Heading size='md' className='text-center'>
          SELECT PAYMENT OPTION BELOW
        </Heading>
        <Heading size='sm'>Payment Options</Heading>
        <Box>
          <Radio defaultChecked>
            <Text as='b'>Pay With Payoneer /Bank Transfer</Text>
          </Radio>
          <LogoPayneer />
        </Box>
        <Text as='u' className='font-bold cursor-pointer' onClick={onOpen}>
          Terms And Conditions
        </Text>
        <Checkbox onChange={onClickTerms}>I accept Terms and conditions</Checkbox>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <Terms isModal />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentMethod;
