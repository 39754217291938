import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCartListPrice } from '@/api/cart';
import { postOrder } from '@/api/order';
import { usePaymentStore } from '@/store/usePaymentStore';
import { IOrderParams } from '@/types/user';

interface Props {
  activeStep: number;
  agreeTerms: boolean;
  onClickNextStep: () => void;
}
const PaymentSummary = ({ activeStep, agreeTerms, onClickNextStep }: Props) => {
  const { paymentDeliveryInfo } = usePaymentStore();

  const { data: { price } = {} } = useQuery(['price'], () => getCartListPrice());

  const isDisabled = (activeStep === 1 && price !== undefined && agreeTerms) || activeStep < 1;

  const { mutate: checkOrder, isLoading: isSubmitting } = useMutation(
    (orderInfo: IOrderParams) => postOrder(orderInfo),
    {
      onSuccess: () => {
        onClickNextStep();
      },
    },
  );
  const onClickOrder = () => {
    if (!isDisabled || !paymentDeliveryInfo || isSubmitting) return;
    const delivery_address = {
      address1: paymentDeliveryInfo.address_number_building,
      address2: paymentDeliveryInfo.address_street,
      city: paymentDeliveryInfo.address_city,
      state: '',
      post_code: paymentDeliveryInfo.address_post_code,
      country: paymentDeliveryInfo.address_country,
      first_name: paymentDeliveryInfo.address_first_name,
      last_name: paymentDeliveryInfo.address_last_name,
      phone_number: paymentDeliveryInfo.address_phone_number,
    };

    checkOrder({ delivery_address, invoice_email: paymentDeliveryInfo.address_invoice_email });
  };

  return (
    <Flex className='flex-col self-start' flex={1}>
      <Flex className='flex-col p-12 rounded-lg gap-4' bg='white'>
        <Heading className='mb-14 w-full justify-center flex' size='lg'>
          INFORMATION
        </Heading>

        <Divider />
        <Flex className='justify-between'>
          <Text>Total</Text>
          <Text>{price} EUR</Text>
        </Flex>

        {price !== undefined ? (
          <Flex className='justify-between'>
            <Text className='mr-2'>Delivery fee</Text>
            <Text> Estimating</Text>
          </Flex>
        ) : (
          <Text color='#C7C7C7'>Delivery fee</Text>
        )}
        <Flex className='justify-between gap-8 whitespace-nowrap '>
          <Heading size='md'>Full price</Heading>
          <Heading size='md'>{price} EUR + Delivery Fee</Heading>
        </Flex>
        <Divider className='border-solid !border-2 !border-black !opacity-100' />
        <Button
          variant={isDisabled ? 'primary' : 'disabled'}
          className='mt-4'
          size='lg'
          type='submit'
          onClick={activeStep !== 1 ? onClickNextStep : onClickOrder}
          disabled={isSubmitting}
        >
          Go to payment
        </Button>
      </Flex>
      {price === undefined && activeStep > 1 && (
        <Text color='#498BF4' className='mt-4'>
          Estimating delivery fee in process.
          <br /> It may take some time!
          <br /> We will email you when it’s ready.
        </Text>
      )}
    </Flex>
  );
};

export default PaymentSummary;
