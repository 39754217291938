/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Flex, Image, Heading, Button, IconButton } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { findFitment, getPartCrossReference } from '@/api/part';
import { ReactComponent as IconTrash } from '@/assets/ic-actions-trash.svg';
import { ICrossRef, IPartItem } from '@/types/parts';
import { IFilterItem } from '@/types/filter.ts';

interface Props {
  type?: string;
  item: IPartItem;
  onClickAddCart?: (e: MouseEvent<HTMLButtonElement>, id?: number) => void;
  onClickDeleteCart?: () => void;
}

const ProductInfo = ({ type = 'detail', item, onClickAddCart, onClickDeleteCart }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isDetailType = type === 'detail';
  const {
    price,
    part_name,
    brand_name,
    images = [],
    car_year,
    part_number,
    sku,
    quality,
    model_name,
    retail_store,
  } = item || {};
  const { data: crossRefs } = useQuery(
    ['crossRef', item.part_number],
    () => getPartCrossReference(item.part_number ?? 0),
    {
      enabled: item.part_number !== '',
      select: data => data.items,
      staleTime: 50000,
    },
  );

  const onClickProductDetail = () => {
    queryClient.prefetchQuery({
      queryKey: ['fitment', item.id.toString()],
      queryFn: () => findFitment(item.id),
      staleTime: 3000,
    });
    return navigate(`/product/${item.id}`);
  };

  return (
    <Flex className='px-[40px] py-[30px] rounded-lg bg-white w-full'>
      <Flex className='flex-col rounded-lg bg-white w-full'>
        <Heading size='md' className='mb-6 cursor-pointer' onClick={onClickProductDetail}>
          {part_name}
        </Heading>
        <Flex className='gap-7 leading-5'>
          <Box>
            <Box height='166px' width='236px'>
              <Image
                className='rounded-lg w-full h-full cursor-pointer'
                src={images[0]?.image_url}
                onClick={onClickProductDetail}
              />
            </Box>
          </Box>
          <Box className='font-bold'>
            <Text className='mb-5'>OE Number: {part_number}</Text>
            {crossRefs && crossRefs.length !== 0 && (
              <Flex className='!inline-flex text-[14px]'>
                <Text>
                  Cross-References:{' '}
                  {crossRefs
                    .slice(0, 3)
                    .map(({ part_number: partNumber }) => partNumber)
                    .join(', ')}
                  {crossRefs.length > 3 && <span>...more</span>}
                </Text>
              </Flex>
            )}
            <Flex className='gap-2 text-[14px]'>
              {brand_name && (
                <>
                  <Text>Brand:</Text>
                  <Text>{brand_name}</Text>
                </>
              )}
            </Flex>
            <Flex className='gap-2 text-[14px]'>
              {model_name && (
                <>
                  <Text>Model:</Text>
                  <Text>{model_name}</Text>
                </>
              )}
            </Flex>
            <Flex className='gap-2 text-[14px]'>
              {car_year && (
                <>
                  <Text>Car Year:</Text>
                  <Text>{car_year}</Text>
                </>
              )}
            </Flex>
            <Flex className='gap-2 text-[14px]'>
              {sku && (
                <>
                  <Text>SKU:</Text>
                  <Text>{sku}</Text>
                </>
              )}
            </Flex>
            <Flex className='gap-2 text-[14px]'>
              {quality && (
                <>
                  <Text>Quality:</Text>
                  <Text>{quality}</Text>
                </>
              )}
            </Flex>
            <Flex className='gap-2 text-[14px]'>
              {retail_store.location && (
                <>
                  <Text>Location:</Text>
                  <Text>{retail_store.location}</Text>
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex>
        {isDetailType ? (
          <Flex className='flex-col justify-around mt-10 w-60 text-center'>
            <Heading fontSize='20px'>{Number(price).toLocaleString()} EUR</Heading>
            <Button colorScheme='blue' onClick={onClickAddCart} width='180px'>
              Add to cart
            </Button>
          </Flex>
        ) : (
          <Flex className='items-center gap-[12px]'>
            <h2 className='whitespace-nowrap text-[22px] font-bold'>
              {Number(price).toLocaleString()} EUR
            </h2>
            <IconButton
              aria-label='cart_delete_btn'
              variant='ghost'
              icon={<IconTrash />}
              onClick={onClickDeleteCart}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ProductInfo;
