import React from 'react';
import { Flex, Menu, MenuButton, MenuList, Image, MenuItem, Box, Spacer } from '@chakra-ui/react';
import iconChevronDown from '@/assets/ic-selector-down.png';
import { useFilterStore } from '@/store/useFilterStore';

const SortValue = [
  { title: 'Price : High', value: 'PriceDesc' },
  { title: 'Price : Low', value: 'Price' },
  { title: 'Newest', value: 'CreatedAtDesc' },
  { title: 'Oldest', value: 'CreatedAt' },
];

const Sorter = () => {
  const {
    searchQuery: { sorter },
    setSorter,
  } = useFilterStore();

  const onClickItem = (key: string, value: string) => {
    setSorter(key, value);
  };

  return (
    <Box className='!w-56 '>
      <Menu matchWidth>
        <MenuButton
          bg='white'
          borderRadius='md'
          borderWidth='1px'
          className='h-12 w-full pl-4 pr-4 text-left'
        >
          <Flex className='items-center justify-between font-semibold'>
            {sorter.title} <Image src={iconChevronDown} />
          </Flex>
        </MenuButton>
        <MenuList overflow='auto'>
          {SortValue.map(item => (
            <MenuItem key={item.value} onClick={() => onClickItem(item.title, item.value)}>
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Sorter;
