import { useNavigate, useSearchParams } from 'react-router-dom';
import { Heading, Text, Button, VStack, Box, Flex, Spinner } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';
import { findPwUser, resetPwUser } from '@/api/auth';
import ControlledInput from '@/components/ControlledInput/ControlledInput';
import useModal from '@/hooks/useModal';

const defaultValues = {
  email: '',
  phone_number: '',
  password: '',
  re_password: '',
};

const phoneRegex = new RegExp(/^\d+$/);

const findPwSchema = object({
  email: string().email().nonempty('Field is Required'),
  phone_number: string()
    .nonempty('Field is Required')
    .regex(phoneRegex, 'Please input only numbers. No special characters are allowed.')
    .min(4, 'Phone Number must be more than 4 numbers'),
});

const changePwSchema = object({
  email: string().email().nonempty('Field is Required'),
  password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
  re_password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
}).refine(data => data.password === data.re_password, {
  path: ['re_password'],
  message: 'Password did not match',
});

type findPwInput = TypeOf<typeof findPwSchema>;
type changePwInput = TypeOf<typeof changePwSchema>;

const FindPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  console.log(token);
  const navigate = useNavigate();
  const { ChaModal, onOpen, onClose } = useModal();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(token ? changePwSchema : findPwSchema),
    defaultValues,
  });

  const {
    mutate: findPwMutate,
    isSuccess: isSuccessSendMail,
    isLoading: isLoadingSendMail,
    data: sendMailResult,
  } = useMutation(({ email, phone }: { email: string; phone: string }) => findPwUser(email, phone));

  const {
    mutate: resetPwMutate,
    isLoading: isLoadingChangePw,
    data: changePwResult,
  } = useMutation(
    ({ pw, resetToken }: { pw: string; resetToken: string }) => resetPwUser(pw, resetToken),
    {
      onSuccess: () => {
        onOpen();
      },
    },
  );

  const onSubmitSendEmail: SubmitHandler<findPwInput> = data => {
    if (isSubmitting || isLoadingSendMail) return;
    findPwMutate({ email: data.email, phone: data.phone_number });
    reset();
  };

  const onSubmitChangePw: SubmitHandler<changePwInput> = data => {
    if (!token) return;
    if (isSubmitting || isLoadingChangePw) return;
    resetPwMutate({ pw: data.password, resetToken: token });
  };

  const onClickModalConfirm = () => {
    changePwResult?.ok ? navigate('/') : onClose();
  };

  return (
    <div className='mb-14 mt-28 px-36'>
      <Heading mb='7' size='lg'>
        {!token ? 'FIND PASSWORD' : 'CHANGE PASSWORD'}
      </Heading>
      {!token ? (
        <VStack spacing={4} align='stretch' as='form' onSubmit={handleSubmit(onSubmitSendEmail)}>
          <Text color='gray.500' fontWeight='medium'>
            We will send you a link to change your password via email.
          </Text>
          <Box>
            <ControlledInput label='Email' name='email' control={control} />
          </Box>
          <Box>
            <ControlledInput label='Phone Number' name='phone_number' control={control} />
          </Box>
          {(isSubmitting || isLoadingSendMail) && (
            <Spinner thickness='4px' size='sm' color='rgb(25 71 141 / 94%)' />
          )}
          {isSuccessSendMail &&
            (sendMailResult?.ok ? (
              <Text>Email sent.</Text>
            ) : (
              <Text color='#e53e3e'>
                The entered phone number or email does not match the one used for registration.
              </Text>
            ))}
          <Box display='flex' mt='2' className='w-full flex-col'>
            <Button className='mb-5 w-full' colorScheme='blue' size='lg' type='submit'>
              Send
            </Button>
          </Box>
        </VStack>
      ) : (
        <>
          <Flex className='flex-col gap-4' as='form' onSubmit={handleSubmit(onSubmitChangePw)}>
            <Text color='gray.500' fontWeight='medium'>
              We will send you a link to change your password via email.
            </Text>
            <ControlledInput label='Email' name='email' control={control} />
            <ControlledInput label='New Password' control={control} name='password' />
            <ControlledInput label='Re-Confirm New Password' control={control} name='re_password' />
            <Button className='my-5 w-full' colorScheme='blue' size='lg' type='submit'>
              Confirm
            </Button>
          </Flex>
          <ChaModal
            modalText={
              changePwResult?.ok
                ? 'Your password has been successfully changed.'
                : 'Error. Could not change password.'
            }
            buttonText={'Confirm'}
            onClick={onClickModalConfirm}
          />
        </>
      )}
    </div>
  );
};

export default FindPassword;
