import { extendTheme } from '@chakra-ui/react';
import { Button } from '@/theme/components/button';
import { dividerTheme } from '@/theme/components/divider';
import { inputTheme } from '@/theme/components/input';
import { menuTheme } from '@/theme/components/menu';
import { tabsTheme } from '@/theme/components/tab';

const overrides = {
  styles: {
    global: () => ({
      body: {
        fontFamily: 'sans-serif',
      },
    }),
  },
  // borders,
  components: {
    Input: inputTheme,
    Button,
    Menu: menuTheme,
    Divider: dividerTheme,
    Tabs: tabsTheme,
  },
};

export default extendTheme(overrides);
