import { useState, MouseEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Spinner,
  Image,
  Grid,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import Pagination from '@/components/Pagination';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addCartItem } from '@/api/cart';
import reset from '@/assets/ic-reset.svg';
import CartModal from '@/components/CartModal';
import Filter from '@/components/Filter';
import ProductItem from '@/components/ProductItem';
import Sorter from '@/components/Sorter';
import { FILTER_ITEMS } from '@/constants';
import { usePartItems } from '@/queries/usePartItems';
import { useFilterStore, useLocationFilterStore } from '@/store/useFilterStore';
import { FilterEnum, IFilterItem, ILocationItem } from '@/types/filter';
import * as Sentry from '@sentry/react';

const ProductList = () => {
  const navigate = useNavigate();
  const isInitialized = useRef(false);
  const queryClient = useQueryClient();
  const { searchQuery, setOffset, setFilterReset, setFilterLocation } = useFilterStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { initFilter } = useLocationFilterStore();
  const {
    data: { items, currentPage, hasNext, total } = {},
    isSuccess,
    refetch,
    isLoading,
  } = usePartItems(searchQuery, true, searchQuery.offset);
  const { mutateAsync: addCart } = useMutation((id: number) => addCartItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['cartCount']);
    },
  });

  const onClickAddCart = async (e: MouseEvent<HTMLButtonElement>, id?: number) => {
    e.stopPropagation();
    if (!id) return;
    try {
      await addCart(id);
      onOpen();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const onClickMenuItem = (item: ILocationItem) => {
    setFilterLocation(item);
  };

  useEffect(() => {
    if (!isInitialized.current) {
      refetch();
      isInitialized.current = true;
    }
    window.scrollTo(0, 0);
  }, [refetch, searchQuery.offset]);

  return (
    <Flex bg='#eff7ff' className='gap-8 px-24 py-36 h-full w-full relative justify-center'>
      <Grid
        className='max-w-[1440px] min-w-[1024px] gap-[40px] w-full'
        gridTemplateColumns={'2.6fr 7.1fr'}
      >
        <Flex className='flex-col gap-4 w-full sticky top-8 h-fit'>
          <Flex className='justify-between items-center mb-[32px]'>
            <Heading fontSize='20px' fontWeight='700'>
              FILTER
            </Heading>
            <Button
              bg='transparent'
              color='#498BF4'
              onClick={() => setFilterReset(initFilter)}
              justifyContent='end'
              gap='4px'
              _hover={{ backgroundColor: 'transparent' }}
            >
              Reset
              <Image src={reset} />
            </Button>
          </Flex>
          {FILTER_ITEMS.map(item => (
            <Filter key={item.type} type={item.type as FilterEnum} placeholder={item.placeholder} />
          ))}
          <Flex columnGap={3} rowGap={1} flexWrap={'wrap'}>
            {searchQuery.filter.location.map(
              location =>
                location.name !== '' &&
                location.name !== 'init' && (
                  <div
                    onClick={() => onClickMenuItem(location)}
                    className={
                      'flex bg-white items-center py-1 px-2 border-black border rounded-[12px]'
                    }
                  >
                    <div className={'mr-2'}>{location.name}</div>
                    <CloseButton width='18' height='18' />
                  </div>
                ),
            )}
          </Flex>
        </Flex>
        <Flex className='grow flex-col items-end w-full'>
          {!isLoading &&
            (isSuccess && total !== 0 ? (
              <>
                <Sorter />
                <div className='mb-[45px]' />
                <Flex className='flex-col rounded-md bg-white w-full'>
                  {items?.map(item => (
                    <ProductItem
                      key={item.id}
                      item={item}
                      onClickAddCart={e => onClickAddCart(e, item.id)}
                    />
                  ))}
                </Flex>
                <Flex className='w-full justify-center mt-12'>
                  <Pagination
                    total={total}
                    offset={searchQuery.offset}
                    onChangeOffset={offset => setOffset(offset)}
                    page_size={20}
                  />
                </Flex>
              </>
            ) : (
              <Text className='w-full text-center mt-32' color='#498BF4'>
                <div>
                  We found no parts according to your request. <br />
                  Please email us and we will help you find the required part. <br />
                </div>
                <button
                  type='button'
                  className='w-full underline text-center font-bold'
                  onClick={() => navigate('/contact')}
                >
                  Contact Us
                </button>
              </Text>
            ))}
          {isLoading && (
            <div className='w-full flex justify-center py-[200px]'>
              <Spinner thickness='4px' size='xl' color='rgb(25 71 141 / 94%)' />
            </div>
          )}
        </Flex>
        <CartModal isOpen={isOpen} onClose={onClose} />
      </Grid>
    </Flex>
  );
};

export default ProductList;
