import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string } from 'zod';
import { updateUserPassword } from '@/api/auth';
import ControlledInput from '@/components/ControlledInput/ControlledInput';
import { useAuthStore } from '@/store/useAuthStore';
import { IPassword } from '@/types/user';
import useModal from '@/hooks/useModal';

const changePwSchema = object({
  old_password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
  new_password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
  new_re_password: string()
    .nonempty('Field is Required')
    .min(4, 'Password must be more than 4 characters'),
}).refine(data => data.new_password === data.new_re_password, {
  path: ['new_re_password'],
  message: 'Password did not match',
});

const MyPassword = () => {
  const { authUser, setAuthUser } = useAuthStore();
  const { ChaModal, onOpen, onClose } = useModal();

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(changePwSchema),
    defaultValues: {
      old_password: '',
      new_password: '',
      new_re_password: '',
    },
  });
  const { mutate: updatePw } = useMutation(({ id, pw }: { id: number; pw: IPassword }) =>
    updateUserPassword(id, pw),
  );

  const onClickUpdatePw: SubmitHandler<IPassword> = data => {
    if (!authUser) return;
    updatePw({ id: authUser.id, pw: data });
  };

  const onClickModalConfirm = () => {
    reset();
    onClose();
  };

  return (
    <div className='h-full'>
      <Heading size='lg' className='mb-8'>
        CHANGE YOUR PASSWORD
      </Heading>
      <Flex className='flex-col' as='form' gap='6' onSubmit={handleSubmit(onClickUpdatePw)}>
        <Text className='font-bold text-xl' color='#808080'>
          Here you can change your password.
        </Text>
        <ControlledInput name='old_password' label='Old Password' control={control} />
        <ControlledInput name='new_password' label='New Password' control={control} />
        <ControlledInput name='new_re_password' label='Re-Confirm New Password' control={control} />
        <Button type='submit' variant='primary' onClick={onOpen}>
          Confirm
        </Button>
        <ChaModal
          modalText={'Your password has been successfully changed.'}
          buttonText={'Confirm'}
          onClick={onClickModalConfirm}
        />
      </Flex>
    </div>
  );
};

export default MyPassword;
