import { IPartItem } from '@/types/parts';
import { http } from '@/utils/http';

export const getCartListPrice = (): Promise<{ price: number }> => {
  return http.get('/shopping/wish-item/price');
};

export const getCartList = (): Promise<{ id: number; product: IPartItem }[]> => {
  return http.get('/shopping/wish-item');
};

export const addCartItem = (product_id: number) => {
  return http.post('/shopping/wish-item', { product_id });
};

export const deleteCartItem = (id: number) => {
  return http.delete(`/shopping/wish-item/${id}`);
};
