import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
	button: {
		_expanded: {
			bg: 'white',
			border: '1px solid #498BF4',
		},
		border: '1px solid #2F2F2F',
	},
	list: {
		marginTop: '-4px',
		border: '1px solid #2F2F2F',
		maxHeight: '672px',
		overflow: 'scroll',
		zIndex: 11,
	},
	item: {
		fontWeight: 'bold',
		_hover: {
			color: 'white',
			bg: '#498BF4',
		},
		_focus: {
			bg: 'none',
		},
	},
});

const variants = {
	language: {
		button: {
			_expanded: {
				bg: 'transparent',
				border: 'none',
			},
			border: 'none',
		},
		list: {
			marginTop: '-8px',
			border: '1px solid #2F2F2F',
			zIndex: 11,
		},
		item: {
			fontWeight: 'bold',
			color: '#2F2F2F',
			_hover: {
				color: 'white',
				bg: '#498BF4',
			},
			_focus: {
				bg: 'none',
			},
		},
	},
};

export const menuTheme = defineMultiStyleConfig({ baseStyle, variants });
