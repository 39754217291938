/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Box, Divider, HStack, Heading, Text, Image } from '@chakra-ui/react';
import Slider, { CustomArrowProps } from 'react-slick';
import aboutUs01 from '@/assets/about-us-01.png';
import aboutUs02 from '@/assets/about-us-02.png';
import aboutUs03 from '@/assets/about-us-03.jpg';
import aboutUs04 from '@/assets/about-us-04.jpg';
import { ReactComponent as IconNext } from '@/assets/ic-next-btn.svg';
import { ReactComponent as IconPrev } from '@/assets/ic-prev-btn.svg';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <IconPrev
    {...props}
    className={`slick-prev slick-arrow${
      currentSlide === 0 ? ' slick-disabled' : ''
    } !-left-20 !h-12 !w-12`}
  />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <IconNext
    {...props}
    className={`slick-next slick-arrow${
      currentSlide === slideCount ?? 0 - 1 ? ' slick-disabled' : ''
    } !-right-20 !h-12 !w-12`}
  />
);

const AboutUs = () => {
  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);

  return (
    <Box className=' py-20 w-full flex justify-center' bg='#f5f5f5'>
      <div className='max-w-[1440px] min-w-[1024px] grow'>
        <h1 className='mb-8 mt-14 text-[20px] font-bold'>About US</h1>
        <Box bg='#FCFCFC' className='py-[32px] px-[40px] shadow-md'>
          <Text className='font-bold '>
            <div>We are Korean used auto parts supplier which associate with Korea Auto</div>
            <div>Dismantle & Recycle Association (160 dismantlers).</div>
            <div>We have office in South Korea, Lithuania and Ukraine.</div>
            <div className='mt-[30px]'>
              In Korea, annually 600,000 units of Hyundai, KIA, Ssangyong, Chevrolet
            </div>
            <div>and Renault are dismantled with excellent condition;</div>
          </Text>
          <Text className='text-[14px] mt-[30px]'>
            <div>• Average vehicle 13.5 years.</div>
            <div>• Accident insurance repair with OE parts.</div>
            <div>• Excellent road condition.</div>
            <div>• Competitive maintenance cost.</div>
            <div>• No damage from Calcium Chloride.</div>
            <div>• We provide 30 days free returns and quality guarantee for 120 days.</div>
          </Text>
        </Box>
        <h1 className='mb-8 mt-14 text-[20px] font-bold'>GALLERY</h1>
        <HStack className='mb-4 h-6 text-[18px]'>
          <Divider
            orientation='vertical'
            className='!border-4 !border-solid !border-black !opacity-100'
          />
          <Text as='b'>Our Building & Showroom</Text>
        </HStack>
        <Box maxWidth='100vw' width='1200px' className='mt-7 m-auto'>
          <Slider
            dots={false}
            nextArrow={<SlickArrowRight />}
            prevArrow={<SlickArrowLeft />}
            asNavFor={nav2 as Slider}
            ref={slider => setNav1(slider)}
            afterChange={() => console.log('123')}
          >
            <Image src={aboutUs01} className='mr-4' />
            <Image src={aboutUs02} className='mr-4' />
            <Image src={aboutUs03} className='mr-4' />
            <Image src={aboutUs04} className='mr-4' />
          </Slider>
        </Box>
        <Box maxWidth='100vw' width='1200px' className='mt-4 m-auto'>
          <Slider
            asNavFor={nav1 as Slider}
            ref={slider => setNav2(slider)}
            focusOnSelect
            slidesToShow={5}
          >
            <Image src={aboutUs01} className='px-2 py-2' />
            <Image src={aboutUs02} className='px-2 py-2' />
            <Image src={aboutUs03} className='px-2 py-2' />
            <Image src={aboutUs04} className='px-2 py-2' />
            {/* For: 5장 배열했을 때의 사이즈와 맞추기 위해 추가 */}
            <Image src={aboutUs01} className='invisible' />
          </Slider>
        </Box>
      </div>
    </Box>
  );
};

export default AboutUs;
