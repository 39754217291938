import { create } from 'zustand';
import { FilterEnum, IFilter, IFilterItem, ILocationItem, ISearchQuery } from '@/types/filter';

interface FilterState {
  searchQuery: ISearchQuery;
  setInitFilterLocation: (values: ILocationItem[]) => void;
  setFilter: (key: string, value: IFilterItem) => void;
  setFilterLocation: (value: ILocationItem) => void;
  setFilterReset: (value: any) => void;
  setSorter: (key: string, value: string) => void;
  setNumber: (key: string, value: string) => void;
  setQuery: (value: string) => void;
  setQueryReset: () => void;
  setOffset: (value: number) => void;
}

interface InitFilterState {
  initFilter: IFilter;
  setInitLocation: (value: ILocationItem[]) => void;
}

const initialFilter = {
  brand_id: { id: 0, name: '' },
  model_id: { id: 0, name: '' },
  classification_id: { id: 0, name: '' },
  category_option_id: { id: 0, name: '' },
  category_id: { id: 0, name: '' },
  location: [{ name: 'init' }],
};

export const useLocationFilterStore = create<InitFilterState>(set => ({
  initFilter: initialFilter,
  setInitLocation: (value: ILocationItem[]) =>
    set(state => ({ initFilter: { ...state.initFilter, location: value } })),
  setInitFilter: (value: IFilter) => set(_ => ({ initFilter: { ...value } })),
}));

export const useFilterStore = create<FilterState>(set => ({
  searchQuery: {
    filter: initialFilter,
    sorter: {
      title: 'Sort after',
      ordering: 'CreatedAtDesc',
    },
    number: {
      vin_number: '',
      part_number: '',
    },
    query: '',
    offset: 0,
  },
  setInitFilterLocation: value => {
    set(state => ({
      ...state,
      searchQuery: {
        ...state.searchQuery,
        filter: { ...state.searchQuery.filter, location: value },
      },
    }));
  },
  setFilter: (key, value) =>
    set(state => {
      let newFilter = state.searchQuery.filter;
      if (key === 'brand_id') {
        newFilter = { ...state.searchQuery.filter, [key]: value };
      }
      if (key === 'model_id') {
        newFilter = {
          ...state.searchQuery.filter,
          ['brand_id']: { ...state.searchQuery.filter['brand_id'] },
          [key]: value,
        };
      }
      if (key === 'classification_id') {
        newFilter = { ...state.searchQuery.filter, classification_id: value };
      }
      if (key === 'category_id') {
        newFilter = {
          ...state.searchQuery.filter,
          category_id: value,
          category_option_id: initialFilter.category_option_id,
        };
      }
      if (key === 'category_option_id') {
        newFilter = { ...state.searchQuery.filter, category_option_id: value };
      }
      console.log(newFilter);
      return {
        searchQuery: {
          ...state.searchQuery,
          filter: newFilter,
        },
      };
    }),
  setFilterReset: (value: IFilter) =>
    set(state => {
      return {
        searchQuery: {
          ...state.searchQuery,
          filter: { ...value },
        },
      };
    }),
  setFilterLocation: value =>
    set(state => {
      let newFilter = { ...state.searchQuery.filter };
      let locationList = [...newFilter.location];
      if (locationList.findIndex(location => location.name === value.name) !== -1) {
        const locationIdx = locationList.findIndex(location => location.name === value.name);
        if (locationIdx === 0 && locationList.length === 1) {
          locationList = [{ name: '' }];
        } else {
          locationList = [
            ...locationList.slice(0, locationIdx),
            ...locationList.slice(locationIdx + 1),
          ];
        }
      } else {
        if (locationList[0].name === '' || locationList[0].name === 'init') locationList.shift();
        locationList.push(value);
      }
      newFilter = {
        ...state.searchQuery.filter,
        location: locationList,
      };
      return {
        searchQuery: {
          ...state.searchQuery,
          filter: newFilter,
        },
      };
    }),
  setSorter: (key, value) =>
    set(state => ({
      searchQuery: {
        ...state.searchQuery,
        sorter: { title: key, ordering: value },
      },
    })),
  setNumber: (key, value) =>
    set(state => ({
      searchQuery: {
        ...state.searchQuery,
        number: { [key]: value },
      },
    })),
  setQuery: value => set(state => ({ searchQuery: { ...state.searchQuery, query: value } })),
  setQueryReset: () =>
    set(state => ({
      searchQuery: {
        ...state.searchQuery,
        query: '',
      },
    })),
  setOffset: value => set(state => ({ searchQuery: { ...state.searchQuery, offset: value } })),
}));
