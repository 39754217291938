import AboutUs from '@/pages/AboutUs';
import Cart from '@/pages/Cart';
import ContactUs from '@/pages/ContactUs';
import FindPassword from '@/pages/FindPassword';
import Home from '@/pages/Home';
import MyPage from '@/pages/MyPage/MyPage';
import Payment from '@/pages/Payment';
import ProductCompare from '@/pages/ProductCompare/ProductCompare';
import ProductDetail from '@/pages/ProductDetail';
import ProductList from '@/pages/ProductList';
import SignIn from '@/pages/SignIn';
import SignUp from '@/pages/SignUp';
import Terms from '@/pages/Terms';

export const routerData = [
  { id: 0, path: '/', element: <Home />, withAuth: false },
  {
    id: 1,
    path: '/about-us',
    element: <AboutUs />,
    withAuth: false,
  },
  {
    id: 2,
    path: '/contact',
    element: <ContactUs />,
    withAuth: false,
  },
  {
    id: 3,
    path: '/terms',
    element: <Terms />,
    withAuth: false,
  },
  {
    id: 4,
    path: '/sign-in',
    element: <SignIn />,
    withAuth: false,
  },
  {
    id: 5,
    path: '/sign-up',
    element: <SignUp />,
    withAuth: false,
  },
  {
    id: 6,
    path: '/find-pw',
    element: <FindPassword />,
    withAuth: false,
  },
  {
    id: 7,
    path: '/my-page/:type',
    element: <MyPage />,
    withAuth: true,
  },
  {
    id: 8,
    path: '/my-page/orders/:orderId',
    element: <MyPage />,
    withAuth: true,
  },
  {
    id: 9,
    path: '/products',
    element: <ProductList />,
    withAuth: false,
  },
  {
    id: 10,
    path: '/products/compare/:productId',
    element: <ProductCompare />,
    withAuth: false,
  },
  {
    id: 11,
    path: '/product/:productId',
    element: <ProductDetail />,
    withAuth: false,
  },
  {
    id: 12,
    path: '/cart',
    element: <Cart />,
    withAuth: false,
  },
  {
    id: 13,
    path: '/payment',
    element: <Payment />,
    withAuth: true,
  },
  {
    id: 14,
    path: '/payment:paymentId',
    element: <Payment />,
    withAuth: true,
  },
];
