import pickby from 'lodash.pickby';
import { IPassword, IUser } from '@/types/user';
import { http } from '@/utils/http';

export const signUpUser = (user: Omit<IUser, 'id'>) => {
  return http.post('/user/signup', {
    ...user,
  });
};

export const signInUser = (signInInfo: {
  email: string;
  password: string;
}): Promise<{ access: string; refresh: string }> => {
  return http.post('/auth/token', signInInfo);
};

export const refreshUser = (refresh: string) => {
  return http.post('/auth/token/refresh', { refresh });
};

export const getUserProfile = (): Promise<IUser> => {
  return http.get('/user/profile');
};

export const updateUserProfile = (id: number, user: IUser): Promise<IUser> => {
  const parsedParams = pickby(user);
  return http.put(`/user/profile/${id}`, { ...parsedParams });
};

export const updateUserPassword = (id: number, passwordInfo: IPassword) => {
  return http.put(`/user/profile/password/${id}`, passwordInfo);
};

export const findPwUser = (email: string, phone: string): Promise<{ ok: boolean }> => {
  return http.post('/user/find-password', { email, phone_number: phone });
};

export const resetPwUser = (
  password: string,
  token: string,
): Promise<{ ok: boolean; reason?: string }> => {
  return http.post('user/reset-password', { password, token });
};
