import { NavLink } from 'react-router-dom';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getCartList } from '@/api/cart';
import bgImg from '@/assets/bg-nav.png';
import { ReactComponent as IconUser } from '@/assets/ic-actions-user.svg';
import { ReactComponent as IconCart } from '@/assets/ic-ecommerce-cart.svg';
import { ReactComponent as EnoPartsLogo } from '@/assets/logo.svg';
import LanguageSelector from '@/components/LanguageSelector';
import { useAuthStore } from '@/store/useAuthStore';

const Navigation = () => {
  const { authUser } = useAuthStore();

  const { data: count } = useQuery(['carts', authUser], () => getCartList(), {
    select: data => data?.length ?? 0,
    enabled: Boolean(authUser),
  });

  return (
    <Flex
      className='justify-center align-middle h-[80px]'
      style={{
        backgroundImage: `linear-gradient(128deg, rgb(75,125,202, 0.8), rgb(25,71,141, 0.94), rgb(0,49,124, 0.94)), url(${bgImg})`,
      }}
    >
      <Flex
        className='justify-between text-white max-w-[1440px] min-w-[1024px] items-center'
        flexGrow='1'
      >
        <NavLink to='/'>
          <EnoPartsLogo />
        </NavLink>
        <Flex className='flex'>
          <div className='flex items-center gap-[32px] whitespace-nowrap text-[14px]'>
            <NavLink className='font-bold' to='/about-us'>
              About us
            </NavLink>
            <NavLink className='font-bold' to='/contact'>
              Contacts
            </NavLink>
            <LanguageSelector />
          </div>
          <div className='flex ml-[40px] items-center'>
            <NavLink to='/my-page/orders'>
              <IconUser width={36} height={36} />
            </NavLink>
            <Flex className='flex flex-col items-end relative'>
              <NavLink to='/cart'>
                <IconCart width={36} height={36} />
                <Box
                  className='absolute font-bold flex justify-center items-center'
                  style={{ right: `1px`, top: '6px' }}
                >
                  {count && count !== 0 ? (
                    <>
                      <Icon viewBox='0 0 200 200' color='#E8E8E8' className='absolute' boxSize={6}>
                        <path
                          fill='currentColor'
                          d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                        />
                      </Icon>
                      <Box fontSize={10} color='#225491' className='z-10 font-bold'>
                        {count}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </NavLink>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navigation;
