import { Spinner } from '@chakra-ui/react';

const Spinners = () => {
  return (
    <div className='w-full text-center mt-5'>
      <Spinner thickness='8px' size='xl' color='rgb(25 71 141 / 94%)' />
    </div>
  );
};

export default Spinners;
