import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
	baseStyle: {
		fontWeight: 700,
	},
	sizes: {},
	variants: {
		primary: {
			bg: '#498BF4',
			color: 'white',
		},
		black: {
			bg: '#000',
			color: 'white',
		},
		disabled: {
			bg: '#C7C7C7',
			color: '#808080',
		},
		orange: {
			bg: '#FF5F43',
			color: 'white',
		},
	},
});
