import { create } from 'zustand';
import { IUser } from '@/types/user';

export enum LoginStatus {
  loading = 'loading',
  login = 'login',
  logout = 'logout',
}

interface AuthState {
  authUser: IUser | null;
  loginStatus: LoginStatus;
  setAuthUser: (user: IUser | null) => void;
  setLoginStatus: (status: LoginStatus.login | LoginStatus.logout) => void;
}

export const useAuthStore = create<AuthState>(set => ({
  authUser: null,
  loginStatus: LoginStatus.loading,
  setAuthUser: user => set(() => ({ authUser: user })),
  setLoginStatus: status => set(() => ({ loginStatus: status })),
}));
