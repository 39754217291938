import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from '@/components/Layout';
import { routerData } from './router';

const router = createBrowserRouter([
  ...routerData.map(routerElement => ({
    path: routerElement.path,
    element: <Layout withAuth={routerElement.withAuth}>{routerElement.element}</Layout>,
  })),
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
