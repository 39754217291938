import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import ProductItem from '@/components/ProductItem';
import Sorter from '@/components/Sorter';
import { usePartItems } from '@/queries/usePartItems';
import { useFilterStore } from '@/store/useFilterStore';

const ProductCompare = () => {
	const { searchQuery } = useFilterStore();

	const { data, isSuccess } = usePartItems(searchQuery, true);

	return (
		<Flex bg="#eff7ff" className="gap-8 px-24 py-36 flex-col">
			<Flex>
				<Heading size="lg">HYUNDAI I20 (BC3) - REAR LIGHT 3</Heading>
				<Sorter />
			</Flex>
			<Flex className="flex-col rounded-lg bg-white mt-12">
				{isSuccess && data.items.map((item) => <ProductItem key={item.id} item={item} />)}
			</Flex>
		</Flex>
	);
};

export default ProductCompare;
