import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  SimpleGrid,
  Image,
  Box,
  Text,
  Circle,
  Spacer,
  Card,
} from '@chakra-ui/react';
import backgroundGray from '@/assets/bg-earth-gray-background.png';
import earth from '@/assets/bg-earth.png';
import { ReactComponent as MyVehicle } from '@/assets/icon-my-vehicle.svg';
import { ReactComponent as Logo } from '@/assets/logo-sm.svg';
import Filter from '@/components/Filter';
import FindItem from '@/components/FindItem/FindItem';
import Makers from '@/components/Makers';
import { FILTER_ITEMS } from '@/constants';
import { usePartItems } from '@/queries/usePartItems';
import { useFilterStore, useLocationFilterStore } from '@/store/useFilterStore';
import { FilterEnum } from '@/types/filter';
import EnoPartsServices from '@/components/EnoPartsServices/EnoPartsServices';

const Home = () => {
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = useState(false);
  const { initFilter } = useLocationFilterStore();

  const { searchQuery, setFilterReset } = useFilterStore();
  const {
    filter: { brand_id, category_id, location },
  } = searchQuery;

  const { isSuccess } = usePartItems(searchQuery, isSearch);
  const onClickSearch = () => {
    if (brand_id.name || category_id.name || location.filter(loc => loc.name === '').length === 0) {
      setIsSearch(true);
    }
  };

  useEffect(() => {
    if (isSuccess && isSearch) navigate('/products');
  }, [isSuccess, isSearch, navigate]);

  return (
    <>
      <Box
        className='relative'
        height={500}
        style={{
          backgroundImage: `url(${backgroundGray})`,
          width: '100%',
          height: 500,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Image
          className='absolute z-10 left-1/2 -translate-x-1/2 object-cover w-full'
          src={earth}
          minW='1024px'
          maxW='1440px'
          h='500px'
        />
        <Box
          className='absolute z-20'
          height={500}
          w='100%'
          minW='1024px'
          maxW='1440px'
          left='50%'
          transform='translate(-50%, 0)'
        >
          <Box className='absolute top-1/2 -translate-y-1/2 text-white' left='0'>
            <Text
              className='text-white font-inter text-[20px] tracking-tight'
              style={{ lineHeight: 'normal' }}
            >
              KOREAN
            </Text>
            <Text
              className='text-white text-[54px]'
              style={{ fontFamily: 'Impact', fontStyle: 'normal', lineHeight: '49.68px' }}
            >
              USED AUTO PARTS
            </Text>
            <Text
              className='text-[#3F96FF] text-[86px]'
              style={{ fontFamily: 'Impact', fontStyle: 'normal', lineHeight: '79.12px' }}
            >
              SPECIALIST
            </Text>
          </Box>
          <Box className='absolute right-0 top-1/2 -translate-y-1/2' right='0'>
            <Circle
              size='220px'
              bg='white'
              className='flex-col'
              color='#0D4CAB'
              style={{ boxShadow: '0px 3px 35px 0px #FFF' }}
            >
              <Logo />
              <Heading fontSize='32px' fontFamily='Impact'>
                486,127
              </Heading>
              <Text fontSize='16px' fontFamily='Suite'>
                PARTS
              </Text>
            </Circle>
          </Box>
        </Box>
        {/*<Image src={bgEarth} className='w-full h-full' loading='lazy' />*/}
        <Box className='absolute left-1/2 z-20 -translate-x-1/2 -translate-y-10' top='93%'>
          <Makers />
        </Box>
      </Box>
      <Flex
        flexDirection='column'
        className='items-start justify-center'
        m='180px auto'
        maxW='1440px'
        minW='1024px'
      >
        <Flex>
          <IconButton
            isRound
            aria-label='my vehicle'
            icon={<MyVehicle width='22' height='22' />}
            mr='2'
            bg='gray.100'
          />
          <Heading size='lg'>MY VEHICLES</Heading>
        </Flex>
        <Heading size='md' className='mb-6 mt-8' alignSelf='flex-start'>
          SELECT VEHICLES
        </Heading>
        <Grid gap='4' templateColumns='repeat(3,minmax(339px, 477px))' templateRows='repeat(2,1fr)'>
          {FILTER_ITEMS.map(item => (
            <GridItem key={item.type} className='[&>button]:w-full'>
              <Filter type={item.type as FilterEnum} placeholder={item.placeholder} />
            </GridItem>
          ))}
          <GridItem gridColumn={3}>
            <Flex gap='12px' width='100%'>
              <Button
                flex='1 1 auto'
                h='42px'
                onClick={onClickSearch}
                variant={
                  brand_id.name ||
                  category_id.name ||
                  location.filter(loc => loc.name === '').length === 0
                    ? 'primary'
                    : 'disabled'
                }
                rounded='12px'
              >
                SEARCH
              </Button>
              <Button
                flex='1 1 auto'
                h='42px'
                bg='#FF5F43'
                color='white'
                onClick={() => setFilterReset(initFilter)}
                rounded='12px'
              >
                RESET
              </Button>
            </Flex>
          </GridItem>
        </Grid>
        <Spacer minH='180px' />
        <EnoPartsServices />
      </Flex>
    </>
  );
};

export default Home;
