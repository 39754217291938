import { Box, Stack, Text } from '@chakra-ui/react';

const Terms = ({ isModal }: { isModal?: boolean }) => (
  <div className={'w-full flex justify-center'}>
    <Box
      className={
        isModal ? 'mb-16 px-[180px] py-[40px] grow' : 'py-14 max-w-[1440px] min-w-[1024px] grow'
      }
    >
      <h1 className='text-[20px] font-bold'>TERMS AND CONDITIONS</h1>
      <h2 className='mt-[40px] mb-[24px] text-[18px] font-bold'>
        Terms and Conditions of Sales and Delivery
      </h2>
      <Stack spacing={10} className='h-full'>
        <Text>
          1. In general <br />
          These Terms and conditions of Sale and Delivery (“Conditions”) apply for purchase of
          products on the website www.enoparts.kr, owned by G-Man Auto Parts Corp. (“Eno Parts”).
          Therefore, kindly read the Conditions thoroughly, before you place an order. Any
          derogation from these Conditions shall only be valid upon written consent with Eno Parts.
        </Text>
        <Text>
          2. Prices <br />
          All prices are informed both with and without VAT (Value Added Tax) if the country is in
          the EU. Time of delivery depends on which country the item is to be shipped to, and if
          delivery is to another destination outside EU, additional costs for this will be added.
          The final price is consequently only stated once the delivery country has been informed.
          For shipments outside the European Union, customs duties are imposed according to the
          specific country’s rules, which rules is the customer’s own responsibility to investigate.
          If you hold a commercial account with us, we reserve the right to claim 2% interest per
          month for amounts due if the payment deadline is exceeded. Furthermore, Eno Parts
          maintains ownership of the product until the total purchase amount incl. of interests and
          fees have been paid.
        </Text>
        <Text>
          3. Ordering and contracting <br />
          Ordering is performed through the Web Shop. Compare the original item number or contact
          your local car dealer for extended advice prior to purchase, to avoid error orders. Should
          you need any assistance when purchasing, you may contact Eno Parts’ client center. Opening
          hours available on our homepage. Once you have ordered an item from Eno Parts, you will
          initially receive an order confirmation and when the payment has been processed, you will
          receive an invoice. However, a binding purchase agreement is only entered into when you
          receive a Track & Trace mail confirming that the part has been shipped from that supplier.
          Since we do not have the goods in stock ourselves, the item may be sold out at the
          supplier in question. Therefore, a binding agreement with our supplier is only concluded
          once the goods have been confirmed in stock and shipped from there.
        </Text>
        <Text>
          4. Payment <br />
          When paying by credit card, you are protected against unauthorized use. Eno Parts uses SSL
          (Secure Locket Layer) in the payment system. This means that the data sent in connection
          with credit card purchases is encrypted and thus only card acquirers (such as Payoneer)
          can read them. Neither Eno Parts nor our other partners can decipher the data. The amount
          of your item will only be deducted at dispatch of the item. A higher amount than the one
          you have approved at the time of the purchase can never be deducted. Eno Parts accepts the
          following means of payment: Payoneer Credit cards, Bank transfer & Deposit to Payoneer
          account
        </Text>
        <Text>
          5. Delivery <br />
          Delivery is made to the delivery address stated in the purchase. A physical collection in
          person of the item is not possible. It is stated for each item on the website when an item
          is delivered. The delivery time will also appear on the order confirmation. If the
          customer is not present to receive the item, and as the item is not just to be placed at
          the delivery address, a new delivery of the item will be attempted in agreement with the
          customer. If it is agreed between the customer and the freight supplier that the goods can
          be placed and left at the delivery address, the customer is responsible for the goods from
          the time it has been placed and left. Upon receipt of the item, you should ensure that the
          item is in accordance to what you have ordered. If you find defaults or defects in the
          product, you must contact Eno Parts within a reasonable time and explain the nature of the
          problem (see paragraph 7 on the right of complaint). You are obliged to examine the item
          immediately upon receipt. If the item is damaged during transport, we ask you to contact
          us immediately and place a claim. You may do this either by phone +82-10-4457-7177 or send
          a mail to gmanautopasrt@gmail.com. Eno Parts is not liable for delays due to force
          majeure-like conditions, including war, civil unrest, natural disasters, strikes and
          lockouts, failing supplies of raw materials, fire, damage to Eno Parts or suppliers’
          production manufacturing facilities, failing transportation options, import or export
          conditions, and any other and/or epidemic that delays, restricts or makes it impossible
          for Eno Parts to deliver the item. Eno Parts is not responsible for guarantees given by
          third parties.
        </Text>
        <Text>
          dually though the web site. The customer receives exactly the item shown on the pictures.
          No manuals will be enclosed, as the items sold are used.
        </Text>
        <Text>
          7. Right to claim <br />
          Your purchase is covered by the Regulations of the European Union (Sale of Goods Act),
          including the rules on warranty. The right to claim only applies to the purchased product
          and not to spare parts supplied free of charge. For engines loose spare parts are these:
          clutch, turbo, water pump and nozzles etc. If the item has a defect, or in case there is
          an error in the order, the customer must establish a claim through the Eno Parts claim
          link on the Webs Shop under the customer profile. For used spare parts, please observe,
          that not all defects or wear and tear are covered by the warranty. Whether there is a
          defect or lack of conformity, will be decided after an assessment. For business accounts 6
          months right to claim applies. The claim must be submitted immediately upon or without
          undue delay after the lack of conformity is or should have been detected. For consumers 2
          years right to claim applies. The claim must be submitted within reasonable time after the
          defect has been detected. Within 2 months shall be considered within reasonable time. In
          case of a justified warranty claim, the shipping costs for the return of the item will be
          reimbursed. If the warranty claim is unjustified, the buyer shall bear the costs of
          shipping, including Eno Parts’s costs of returning the product. Eno Parts does not cover
          any costs associated with assembling and dismantling the goods, including workshop
          expenses.
        </Text>
        <Text>
          8. Packaging when returning the item <br />
          If the item in agreement with Eno Parts is to be returned, it is important that you
          package it adequately. If the item is not packaged correctly and suffers damage during
          transportation, you are liable for this damage. If you however have sent the item well
          packed, Eno Parts carries the risk if the item is lost during transportation to our stock.
        </Text>
        <Text>
          9. Right of cancellation. <br />
          You have the right to cancel your purchase within 30 days without justification. The right
          of cancellation expires 30 days after the goods have been received. If you choose to
          cancel a purchase, you must inform Eno Parts within 30 days after receipt of the goods
          about your cancellation. Either by sending us an e-mail or establish a return case through
          the web site under your profile. If you have purchased more than one item in one order,
          you may choose to cancel the purchase of just some or all the items. The cancellation
          deadline is met if you send your cancellation notice or create a return case before the
          cancellation deadline has expired. The business owner also has the right of cancellation;
          however, the items must be returned in the exact same condition and quantity as when the
          goods were received. If you are a consumer and you want to return a product, you are
          liable for any deterioration in the value of the product due to handling the item
          otherwise than necessary to determine the nature, characteristics and manner of operation
          of the product. For example, you are not allowed to mount an engine or gearbox and you are
          not to break the seal / packaging on electronic components, including power box, power
          distributor box, abs power box, and star box assorted. You must return the goods without
          undue delay and not later than 14 days from the date of notice of cancellation and
          commencement of your return. The item must be returned to the warehouse as shown in the
          return document that is e-mailed to you upon your creation of a return case. You may also
          choose to use the cancellation formula at the end of these Terms, but this is not
          mandatory. You bear the cost of return shipping yourself. You also carry the risk of
          damage or loss of the goods unless you have purchased our return shipping. When you cancel
          a purchase, Eno Parts will refund the payment to your account as soon as possible and
          within 14 days after Eno Parts receives your notice that you wish to cancel. The refund is
          made with the same form of payment as was used when ordering (Payoneer, account, debit
          card). However, Eno Parts may withhold payment until the item has been returned to our
          stock or you have provided other documentation that the item has been returned. Remember
          that the item must always be shipped in proper packaging and you yourself carry the risk
          of the item until the item is received at the relevant warehouse. Therefore, save the mail
          receipt incl. shipping cost information and any track & trace numbers. Packages sent per
          collection shall not be received. Eno Parts does not cover any costs associated with
          assembling and dismantling the goods, including workshop expenses.
        </Text>
        <Text>
          10. Special conditions for the purchase of engines <br />
          Replacement engines and gearboxes must be returned as intact and complete as possible. All
          returned items must be emptied of oil and other liquids before shipping. If this is not
          done, you may have to pay for emptying, and possibly cargo cleaning and damage to other
          goods in the car. Some motors may be furnished with a heat sensor with an indicator that
          melts if the engine overheats. Overheating may occur through faulty maintenance or
          competitive driving, and by driving without proper oil or water level. If the heat sensor
          indicator is melted, the right of complaint lapses. In addition, if the engine is not
          installed by an authorized dealer, the right of claim for business owners will lapse.
        </Text>
        <Text>
          11. Special conditions for the purchase of other products <br />
          Cylinder heads (valves, camshaft or similar parts are not covered): Before installing the
          cylinder head, it must be pressure tested and surface ground. If the pressure test shows a
          defect in the cylinder head, the cost of the pressure test will be reimbursed upon
          presentation of the receipt. For business owners however, the amount reimbursed can only
          be a maximum of EUR 70. Electronic components: Most electronic components require
          programming after mounting. You may contact an auto workshop for a more detailed review.
          If the seal of the package has been broken, the right to cancel lapse, please see 9 about
          cancellation. For supply of software, the right to cancel lapses for both consumers and
          business owners, if the package seal has been broken. Mechanical parts: Used mechanical
          parts often have scratches and may be dirty. You are advised to check and compare the part
          number from the removed part before purchasing and installing a new device, so that you
          make sure you are buying the right spare part. Body parts: The condition of a body part
          can be seen in the code indicating the quality of the product. A used body part often has
          scratches and minor flaws which requires painting. Car lamps: Scratches on used lamps may
          occur. There are several types of lighting systems. You can check the type used for your
          car by looking at the light type indicated on the bulb. Xenon control boxes and bulbs are
          not part of the delivery. If a Xenon control box or a bulb is installed on the light, they
          are not covered by the warranty. Panes: Scratches on used glass and panes may occur. You
          can check whether the pan you need is tinted by holding a piece of white paper behind one
          of your other panes. Eno Parts does not provide warranty for the heating wires or antennas
          built into the windows.
        </Text>
        <Text>
          12. Personal Data <br />
          It is important to Eno Parts that your personal data are kept safe and confidential. You
          may read more about our policy on personal data here: www.enoparts.kr/policies
        </Text>
        <Text>
          13. Complaints, Applicable Law and Jurisdiction <br />
          You are always welcome to contact Eno Parts if you have a complaint about an item. If a
          complaint cannot be settled and if you are a consumer, you can file a complaint through
          the EU Commission&apos;s online complaint portal. This is particularly relevant if you are
          a consumer domiciled in another EU country. The complaint can be filed at the following
          link: http://ec.europa.eu/odr. When filing a complaint, Eno Parts’ email:
          gmanautoparts@gmail.com. If you are a business owner, any dispute under the agreement must
          be brought before the Korean or Lithuanian courts at Eno Parts domicile. The agreement
          between you and Eno Parts, including these Terms and Conditions, is governed by Danish
          law, unless otherwise provided by the mandatory rules on consumer protection in the
          country in which you are a resident.
        </Text>
        <Text>
          14. Contact Information <br />
          G-Man Auto Parts Corp. Venture Center 201, Hoseo University, BaebanUb, HoseoRo 79 BunGil
          20, Asan, Chungnam (31499) South Korea. Phone: +82-10-4457-7177 Sales Tax ID number :
          895-81-02287 Policy for processing and protection of personal data Sales Tax ID number :
          895-81-02287 G-Man Auto Parts Corp. appreciates the protection of personal data, and we
          therefore place great emphasis on protecting your privacy. This privacy policy explains
          and clarifies how we handle data management by our visitors and customers in accordance
          with (EU) 2016/279, DG Directorate General for Data Protection (GDPR). This policy applies
          to situations where G-Man Auto Parts Corp. (hereinafter &quot;Eno Parts&quot;) processes
          personal information collected from the website or receives personal information from
          suppliers, customers, etc. 1) Record of personal data processing done by Eno Parts: The
          company processes personal information about: Applicants Employees Users Customers
          Supplier Contacts Eno Parts has prepared a list of personal data processing. The listing
          provides an overview of the treatments for which Eno Parts is responsible. Personal
          information is a prerequisite for Eno Parts to sell and deliver goods to customers, enter
          into employment and supplier contracts, and to inform contact persons with news. 2)
          Personal Information Personal information covers all information that can be used to
          identify a person, including but not limited to his / her first and last name, age,
          gender, personal address or other physical address, e-mail address or other contact
          information, whether they concern their private residence or workplace. 3) Purpose and
          legality of treatment Personal data is processed and archived in connection with:
          Personnel administration, including recruitment, recruitment, resignation and payroll
          Master data for suppliers as well as requisitions and purchases Master data for contacts
          and correspondence Master data for customers as well as orders and sales, including: name
          address email address phone Data for the visit on our website through automated
          processing. 3.1 Purpose We collect the above personal data for the following purposes:
          Delivery of ordered goods General business The processing is legal by virtue of the legal
          basis of the EU Personal Data Regulation. Article 6, section 1 (b) for performance of the
          contract. As mentioned above for our purpose of processing, our processing of these
          personal data also takes place in the light of the interest-weighting rule in data
          protection Article 6, section 1, paragraph f, of the Data Protection Regulation. The
          legitimate interests that justify the processing are marketing purposes and fulfillment of
          service and operating services and agreements with customers as part of the normal
          operation of the AP24. Registration of personal data and personally identifiable
          information is necessary in order to analyze how customers use AP24&apos;s websites
          through Google Analytics. For more information, see the section entitled &apos;4
          Cookies&apos;. The AP24 does not store information about payment methods, such as card
          numbers, bank account numbers, etc., as purchases are made over external companies
          (Payneer). The company does not use personal data for purposes other than those listed.
          The company does not collect more personal information than necessary in relation to the
          fulfillment of the purpose. By using our website you agree that your personal information
          is processed in accordance with this privacy policy. When you are on the Eno Parts’
          websites; www.enoparts.kr www.enoparts.co.kr www.usedautopart.kr www.autoparts24.co.uk
          www.usedautoparts.co.kr or when you submit personal data to the Eno Parts, you agree to
          our personal data policy. Eno Parts is aware of the need for appropriate protection and
          protected processing of all personal information we receive. If you cannot accept data
          policy, please do not use these websites. 4) Cookies Eno Parts uses cookies on all company
          websites. By using our website, you agree to our policy and the use of cookies, as set
          forth in the Eno Parts Cookie Policy. Read more here: www.enoparts.kr/cookies 5)
          Disclosure of information Personal information provided on our website or otherwise to an
          Eno Parts service may be disclosed to external partners that process information on our
          behalf. We use external business partners for technical operations, which provide a basis
          for services to our customers, as well as targeted marketing, including retargeting. These
          companies process data for us and treat only the disclosed personal data after written
          agreement with us and for the above purposes. The Eno Parts uses Google Analytics to
          collect visitor statistics and, in that regard, passes IP addresses to Google Analytics.
          Google may disclose the information to third parties if the law requires so or to third
          parties who perform tasks for third parties. The Google Analytics Privacy Policy is
          accepted by visiting the Eno Parts’ websites and listed at google.com/privacy Information
          about your name, address, e-mail, phone number, order number and specific delivery
          requests are communicated to the carrier who handles the delivery of the purchased goods
          to you (EU carrier service). As the Eno Parts has different suppliers in Europe, located
          within the EU, the said information will be disclosed to our supplier of the product
          concerned, which will take care of the transfer to the carrier and therefore have access
          to information about the customer&apos;s name and address. 6) Storing and deleting
          information G-Man Auto Parts Corp. has the following general guidelines for storing and
          deleting personal information: Customer and user information is stored in IT systems and
          server drives. Employee information is stored in IT systems. Personal data are stored no
          longer than what is necessary for the purpose of the processing. Personal data for
          employees will be deleted five years after termination of employment, and personal data on
          applicants will be deleted after 1 year. Information we have gathered when creating a
          profile and its use, such as in connection with purchases you have made on our website,
          will be deleted - as a starting point, after 2 years without user and purchase activity.
          However, information may be stored for a longer period if we have a legitimate need or
          legal obligation for longer retention, for example, if necessary for legal claims to be
          established, enforced or defended, or if storage is necessary for us to comply with legal
          requirements. Accounting materials are kept for 5 years to the end of a financial year in
          order to comply with the requirements of the Accounting Act. 7) Personal Data Security
          Based on the company&apos;s risk assessment, G-Man Auto Parts Corp has implemented the
          following security measures for the protection of personal data: Only employees who have a
          work-related need are granted access to the registered personal data. All computers have
          passwords, and employees cannot leave personal passwords to others, except for common mail
          accounts for handling customer correspondence where work sharing is required. All
          computers are installed with anti-virus software and security updates that are regularly
          updated. Personal data is properly deleted by phasing out and repairing IT equipment.
          Physical folders are placed in locked cabinets. Personal data in physical folders is
          destroyed by handover at recycling site / incineration plant All employees receive
          instructions on how G-Man Auto Parts Corp. protects personal information, including
          deletion. 8) Personal data protection breach In case of breach on personal data security,
          Eno Parts notifies the Data Inspectorate as soon as possible and within 72 hours. General
          Manager Jesper Kristoffersen is responsible for this. The notification describes the
          breach, which groups of persons it concerns and what consequences the breach may have for
          these people and how the Company has or will correct the breach. In cases where the breach
          entails a high risk to those persons about whom the AP24 processes personal data, the Eno
          Parts shall also inform them. 9) Your rights You have several rights under the Data
          Protection Regulation in relation to our processing of information about you. Your rights
          are as follows: Right to view information (insight) You have the right to gain insight
          into the information we process about you. Right to ascertain You are entitled to receive
          incorrect information about yourself. Right to delete In special cases, you have the right
          to delete information about you prior to the time where our general deletion of your data
          occurs. Right to limited processing You may, in certain cases, have the right to restrict
          the processing of your personal information. If you are entitled to limited processing, in
          future, we may only process the information - except for storage - with your consent, or
          for the purpose of determining, enforcing or defending legal claims, or for protecting a
          person or important public interest. Right to objection In some cases, you may object to
          our otherwise legal processing of your personal information. You can also object to
          processing your information for direct marketing Right to transmit information (data
          portability) You may, in certain cases, have the right to receive your personal data
          information in a structured, commonly used and machine-readable format, as well as to
          transfer such personal data from one data manager to another without hindrance. You may
          read more about your rights in the Data Inspectorate&apos;s Guide on the Registrar&apos;s
          Rights, which you will find at The Information Commissioner’s Office To exercise your
          rights, please contact us through the following contact information in section. 10) Change
          and control of information If you find that the information we have about you is
          incorrect, you can always change your information on your profile at our website:
          www.autoparts-24.com/customer If you want to delete cookies from your browser, please
          follow our guide under our cookies policy www.enoparts.kr/cookies 11) Insight into your
          information If you want to get insight in or deleted your personal information which we
          have stored / collected, please contact us. If you wish to delete your information, we
          reserve the right to retain information pursuant to our obligation to retain information
          in accordance with the Accounting Legislation. 12) Data Processors G-Man Auto Parts Corp.
          uses data-processing companies. Data providers must always guarantee that they ensure the
          necessary measures are taken to comply with the requirements for personal data law. All
          computer users enter into a written agreement with G-Man Auto Parts Corp. before
          processing. 13) Complaints to the Data Inspectorate You are entitled to file a complaint
          with the Data Inspectorate if you are dissatisfied with the way in which AP24 processes
          your personal information. 14) Contact us If you have questions or other inquiries
          regarding our policy on personal data, you are always welcome to contact us by phone or by
          email: Phone: +82-10-4457-7177 Mail: gmanautoparts@gmail.com
        </Text>
      </Stack>
    </Box>
  </div>
);

export default Terms;
