/* eslint-disable camelcase */
import { Box, Flex, HStack, Heading, Image, Text } from '@chakra-ui/react';
import { IPartItem } from '@/types/parts';

interface Props {
  item: IPartItem;
}

const CartModalItem = ({ item }: Props) => {
  const { part_number, quality, part_name, images, price } = item;

  return (
    <Flex bg='white' className='rounded-lg flex-col mb-8' h='166'>
      <HStack>
        <Box w='166px'>
          <Image className='rounded-lg' src={images && images[0]?.image_url} />
        </Box>
        <Flex className='flex-col ml-4 justify-between h-full w-full'>
          <Flex className='flex-col'>
            <Text fontSize='xl'>{part_name}</Text>
            <Text size='md' color='#808080'>
              Original Part Number: {part_number}
            </Text>
            <Text color='#808080'>Quality:{quality}</Text>
          </Flex>
          <Flex className='flex-col text-end'>
            <Heading size='md'>{price} EUR</Heading>
          </Flex>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default CartModalItem;
