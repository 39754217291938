import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { IOrderItem } from '@/types/cart';
import { ORDER_PROCESS } from '@/constants';

interface Props {
  item: IOrderItem;
}

const OrderDetailItem = ({ item }: Props) => {
  return (
    <>
      <Flex className='p-7 py-12 rounded-lg bg-white w-full mb-5' gap='48px'>
        <Box h='125px' w='186.5px' minW='186.5px'>
          {item?.product?.images && (
            <Image
              className='rounded-lg !w-full h-full'
              src={item.product.images?.[0]?.image_url}
            />
          )}
        </Box>
        <Flex className='flex-col flex-1 font-bold' gap='20px'>
          <Heading size='md'>{item?.product?.part_name}</Heading>
          <Box>
            <Text>
              Order Process:
              <span className='font-normal'> {ORDER_PROCESS[item?.status].label}</span>
            </Text>
            <Text>
              Tracking Number:<span className='font-normal'> {item?.shipping_number}</span>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default OrderDetailItem;
