/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_ENV,
  withCredentials: true,
});

export const http = {
  get: function get<Response = unknown>(url: string, args?: any) {
    return instance.get<Response>(url, { params: args }).then(res => res.data);
  },
  post: function post<Response = unknown, Request = any>(url: string, body?: Request) {
    return instance
      .post<Response>(url, body)
      .then(res => res.data)
      .catch(error => {
        Sentry.captureException(error);
        return error.response.data;
      });
  },
  delete: function del<Response = unknown>(url: string, args?: any) {
    return instance.delete<Response>(url, { params: args }).then(res => res.data);
  },
  put: function put<Response = unknown, Request = any>(url: string, body?: Request) {
    return instance.patch<Response>(url, body).then(res => res.data);
  },
};

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('auth');
  if (!token) {
    config.headers.accessToken = null;
    return config;
  }
  const { access } = JSON.parse(token);
  config.headers.Authorization = `Bearer ${access}`;
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError | Error) => {
  if (axios.isAxiosError(error) && error.response) {
    const token = localStorage.getItem('auth');
    const { response, config } = error;
    if (!token || error.response.status !== 401 || !config) {
      return Promise.reject(error);
    }
    if (response.status === 401 && config.url === '/auth/token/refresh') {
      return Promise.reject(error);
    }
    try {
      const { refresh } = JSON.parse(token);
      const res = await instance.post('/auth/token/refresh', { refresh });
      const { access } = res.data;
      if (res.status === 401) {
        return Promise.reject(error);
      }
      localStorage.setItem('auth', JSON.stringify({ refresh, access }));
      config.headers.Authorization = `Bearer ${access}`;
      return await axios(config);
    } catch (refreshError) {
      localStorage.removeItem('auth');
    }
  }
  return Promise.reject(error);
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(onResponse, onResponseError);
