import { Box, Heading, Flex, Image, Text } from '@chakra-ui/react';
import home01 from '@/assets/home-01.png';
import home02 from '@/assets/home-02.png';
import home03 from '@/assets/home-03.png';
import home04 from '@/assets/home-04.png';
import home05 from '@/assets/home-05.png';

const Context = [
  {
    image: home01,
    heading: '1. Effortless Parts Search',
    text: "We specialize exclusively in Hyundai, KIA, Ssanyong, and Chevrolet vehicles. Our intelligent search engine simplifies the process of locating accurate and comparable OE part data for your car. You'll effortlessly find all the parts that perfectly fit your vehicle. Don't hesitate to reach (not each) If you have any doubts about compatibility, fitment, or the OE part number, don't hesitate to each out to us.",
    text_2:
      'Additionally, we offer a convenient wholesale part inquiry option in a downloadable format for those searching for multiple parts.',
  },
  {
    image: home02,
    heading: '2. Convenient Ordering',
    text: 'You can easily place your parts order through our user-friendly online platform or send us your wholesale order via email.',
  },
  {
    image: home03,
    heading: '3. Flexible Payment Options',
    text: ' When you shop with Eno Parts, you have the flexibility to pay using a credit card or opt for a bank transfer via Payoneer.',
  },
  {
    image: home04,
    heading: '4. Swift Shipping and Delivery',
    text: 'Our extensive parts inventory in the EU ensures swift deliveries, typically within 3 to 7 working days. For international orders with parts sourced from Korea, our efficient delivery service takes approximately 10 to 14 working days.',
  },
  {
    image: home05,
    heading: '5. Efficient Returns and Customer Support',
    text: 'If you wish to initiate a return for a purchased part, you can easily create a return case by logging into your account with us. We pride ourselves on providing prompt attention and swift processing for all returns or customer inquiries.',
  },
];

const EnoPartsServices = () => {
  return (
    <Box
      bgColor='#FAFAFB'
      w='full'
      p='70px 170px'
      className='rounded-s-2xl flex flex-col gap-[100px]'
    >
      <Heading color='#808080' size='lg' className='text-center text-normal'>
        Streamlining Your Auto Parts Experience: Eno Parts Services
      </Heading>
      <Flex flexDir='column' gap='50px'>
        {Context.map(article => (
          <Flex gap='50px' key={article.heading}>
            <Image src={article.image} w='316px' h='210px' />
            <Box className='flex flex-col gap-5'>
              <Heading size='md'>{article.heading}</Heading>
              <Text color='#808080'>{article.text}</Text>
              <Text color='#808080'>{article?.text_2}</Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default EnoPartsServices;
