import { FunctionComponent } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';

interface IChaModal {
  modalText?: string;
  buttonText?: string;
  onClick?: () => void;
}

const useModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ChaModal: FunctionComponent<IChaModal> = ({ modalText, buttonText, onClick }) => (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay>
        <ModalContent height='268px'>
          <ModalCloseButton />
          <ModalBody
            display='flex'
            justifyContent='center'
            alignItems='center'
            fontSize='20px'
            fontWeight='500'
          >
            {modalText}
          </ModalBody>
          <ModalFooter display='flex' justifyContent='center' alignItems='center'>
            <Button colorScheme='blue' mr={3} onClick={onClick}>
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );

  return { ChaModal, onOpen, onClose };
};

export default useModal;
