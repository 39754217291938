import logoChevrolet from '@/assets/logo-chevrolet.svg';
import logoHyundai from '@/assets/logo-hyundai.svg';
import logoKia from '@/assets/logo-kia.svg';
import logoSsangyong from '@/assets/logo-ssangyong.svg';

export const FILTER_MAKER = [
  {
    id: 1,
    value: 'Kia',
    src: logoKia,
  },
  {
    id: 2,
    value: 'Hyundai',
    src: logoHyundai,
  },
  {
    id: 3,
    value: 'Ssangyong',
    src: logoSsangyong,
  },
  {
    id: 4,
    value: 'Chevorlet',
    src: logoChevrolet,
  },
];

export const FILTER_CATEGORY = [
  {
    id: 151,
    value: 'Light / Mirrors',
  },
  {
    id: 152,
    value: 'Body',
  },
  {
    id: 153,
    value: 'Chassis',
  },
  {
    id: 154,
    value: 'Trim',
  },
  {
    id: 155,
    value: 'Electric',
  },
  {
    id: 156,
    value: 'Transmisson',
  },
  {
    id: 157,
    value: 'Engine',
  },
];

export const FILTER_ITEMS = [
  { type: 'brand_id', placeholder: 'Select Brand' },
  { type: 'model_id', placeholder: 'Select Model' },
  { type: 'classification_id', placeholder: 'Select Classification' },
  { type: 'category_id', placeholder: 'Select Category' },
  { type: 'category_option_id', placeholder: 'Select Option' },
  { type: 'location', placeholder: 'Select Location' },
];

export const MakerKey = {
  KIA: 'Kia',
  HYUNDAI: 'Hyundai',
  SSANGYONG: 'Ssangyong',
  CHEVROLET: 'Chevrolet',
} as const;

export type MakerEnum = (typeof MakerKey)[keyof typeof MakerKey];

export const COUNTRY = [
  'Albania',
  'Andorra',
  'Armenia',
  'Austria',
  'Azerbaijan',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Kazakhstan',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Turkey',
  'Ukraine',
  'United Kingdom',
];

export const ORDER_PROCESS: { value: number; label: string }[] = [
  { value: 0, label: '' },
  { value: 1, label: 'Order Received' },
  { value: 2, label: 'Order Processing' },
  { value: 3, label: 'Order Processing' },
  { value: 4, label: 'Invoice Sent' },
  { value: 5, label: 'Payment In Process' },
  { value: 6, label: 'Payment Completed' },
  { value: 7, label: 'Payment Completed' },
  { value: 8, label: 'Prepare For Shipment' },
  { value: 9, label: 'Shipping' },
  { value: 10, label: 'Shipped' },
  { value: 11, label: 'Purchase Confirmed' },
  { value: 12, label: 'Order Cancel' },
  { value: 13, label: 'Refund Request' },
  { value: 14, label: 'Refund Processing' },
  { value: 15, label: 'Refund Completed' },
  { value: 16, label: 'Exchange Request' },
  { value: 17, label: 'Exchange Processing' },
  { value: 18, label: 'Exchange Completed' },
  { value: 19, label: 'Delivery Completed' },
];
