import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const dottedBlack = defineStyle({
	borderColor: 'black',
	borderStyle: 'dotted',
	borderWidth: '1px',
});

export const dividerTheme = defineStyleConfig({
	variants: { dottedBlack },
});
