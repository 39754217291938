import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Flex, Heading, Image } from '@chakra-ui/react';

import { FILTER_MAKER } from '@/constants';
import { useFilterStore, useLocationFilterStore } from '@/store/useFilterStore';

const Maker = () => {
  const { setFilter, setFilterReset } = useFilterStore();
  const { initFilter } = useLocationFilterStore();
  const navigate = useNavigate();
  const onClickLogo = (id: number, name: string) => {
    setFilterReset(initFilter);
    setFilter('brand_id', { id, name });
    navigate('/products');
  };

  return (
    <Flex
      width='820px'
      height='137px'
      className='items-center justify-center gap-1 self-center bg-white shadow-lg shadow-gray-300 rounded-[8px]'
    >
      {FILTER_MAKER.map(item => (
        <Fragment key={item.id}>
          <Flex
            key={item.id}
            className='mx-4 my-4 flex-col items-center cursor-pointer'
            width={274}
            onClick={() => onClickLogo(item.id, item.value)}
          >
            <Image src={item.src} height='74px' width='120px' />
            <Heading size='sm' className='mt-2'>
              {item.value.toUpperCase()}
            </Heading>
          </Flex>
          <Divider orientation='vertical' key={`div_${item.value}`} />
        </Fragment>
      ))}
    </Flex>
  );
};
export default Maker;
