import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getCartList } from '@/api/cart';
import CartModalItem from '@/components/CartModalItem';
import { useAuthStore } from '@/store/useAuthStore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CartModal = ({ isOpen, onClose }: Props) => {
  const { authUser } = useAuthStore();
  const { data: carts, isSuccess } = useQuery(['carts'], () => getCartList(), {
    enabled: isOpen && Boolean(authUser),
  });

  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent className='top-52 p-4 w-32 min-w-fit' minWidth='900px'>
        <ModalHeader>
          <Heading size='lg'>Your cart</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {authUser ? (
            carts?.map(item => <CartModalItem key={item.id} item={item.product} />)
          ) : (
            <Flex className='justify-center'>Sign in add to cart</Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex className='w-full justify-center'>
            {authUser ? (
              <Flex className='gap-8'>
                <Button variant='primary' onClick={() => navigate('/cart')}>
                  View cart
                </Button>
                <Button colorScheme='ghost' variant='outline' onClick={onClose}>
                  Continue Shopping
                </Button>
              </Flex>
            ) : (
              <Flex className='gap-8'>
                <Button variant='primary' onClick={() => navigate('/sign-in')}>
                  Login
                </Button>
                <Button colorScheme='ghost' variant='outline' onClick={() => navigate('/sign-up')}>
                  Create Account
                </Button>
              </Flex>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CartModal;
