/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Tooltip,
  Spinner,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { addCartItem } from '@/api/cart';
import { findFitment, getPartCrossReference, getPartItem } from '@/api/part';
import CartModal from '@/components/CartModal';
import ImageGallery from '@/components/ImageGallery';
import './ProductDetail.less';
import * as Sentry from '@sentry/react';

const ProductDetail = () => {
  const { productId = '' } = useParams();
  const navigate = useNavigate();
  const [isCopiedOpen, setIsCopiedOpen] = useState(false);
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: {
      part_name,
      images,
      id = 0,
      quality,
      brand_name,
      model_name,
      car_year,
      fuel,
      transmission,
      body_color,
      vin,
      sku,
      part_number,
      price,
      part_description,
      warranty,
      retail_store,
    } = {},
  } = useQuery(['part', productId], () => getPartItem(productId), {
    enabled: productId !== '',
  });
  const { data: crossRef, isSuccess: isSuccessCrossRef } = useQuery({
    queryKey: ['crossRef', part_number],
    queryFn: () => getPartCrossReference(part_number ?? '0'),
    enabled: !!part_number,
    select: data => {
      return data?.items ?? [];
    },
    retryOnMount: false,
  });

  const { data: fitment, isSuccess: isSuccessFitment } = useQuery(
    ['fitment', productId],
    () => findFitment(productId ?? '0'),
    {
      enabled: !!part_number,
    },
  );
  const { mutateAsync: addCart } = useMutation(() => addCartItem(+productId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['cartCount']);
    },
  });

  const onClickAddCart = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!productId) return;
    try {
      await addCart();
      onOpen();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const onClickCopy = () => {
    setIsCopiedOpen(true);
    setTimeout(() => setIsCopiedOpen(false), 5000);
  };

  return (
    <Flex bg='#eff7ff' className='h-full flex-col gap-16 pt-[120px] pb-[176px] items-center'>
      <Flex className='gap-24 justify-center max-w-[1440px] min-w-[1024px] pr-[32px]'>
        <ImageGallery images={images} />
        <Flex className='flex-col h-full'>
          <Heading size='lg' className='mb-11 grow'>
            {part_name}
          </Heading>
          <Flex fontSize='lg' className='flex-col font-bold grow' as='b' height='100px'>
            <Text>Brand: {brand_name}</Text>
            {part_number && <Text>OE Number : {part_number}</Text>}
            {crossRef && crossRef.length !== 0 && (
              <Text className='flex'>
                Cross-References:{' '}
                {crossRef
                  .slice(0, crossRef.length > 3 ? 3 : crossRef.length)
                  .map(({ part_number: partNumber }) => partNumber)
                  .join(',')}
                {crossRef.length > 3 && <span>...more</span>}
              </Text>
            )}
            {quality && <Text>Quality : {quality}</Text>}
            {warranty && <Text>Warranty : {warranty}</Text>}
            {retail_store && <Text>Location : {retail_store.location}</Text>}
          </Flex>
          <Flex
            bg='#498BF4'
            height='252px'
            color='white'
            className='mt-24 flex-col items-center justify-center rounded-lg grow'
            width='70%'
            alignSelf='center'
          >
            <Heading>{Number(price ?? 0).toLocaleString()} EUR</Heading>
            <Text>
              With VAT {retail_store?.vat}% :{' '}
              {retail_store?.vat && ((Number(price ?? 0) * retail_store?.vat) / 100).toFixed(2)} EUR
            </Text>
            <Button
              onClick={onClickAddCart}
              bg='white'
              color='#0384F9'
              className='mt-5 rounded-lg w-full max-w-[245px] min-w-[190px]'
              height='70px'
              fontSize='xl'
            >
              Add to Cart
            </Button>
          </Flex>
        </Flex>
        <CartModal isOpen={isOpen} onClose={onClose} />
      </Flex>

      <Button
        onClick={() => navigate(-1)}
        bg='#C7C7C7'
        color='white'
        className='rounded-lg w-full max-w-[245px] min-w-[190px]'
        height='70px'
        fontSize='xl'
        _hover={{ color: 'black', backgroundColor: '#E2E8F0' }}
      >
        Back to List
      </Button>

      {isSuccessCrossRef && isSuccessFitment ? (
        <div>
          <Tabs isFitted className='w-full min-w-[1024px] max-w-[1440px]'>
            <TabList>
              <Tab className='border-r-4 !border-r-[#eff7ff]'>Additional Information</Tab>
              {fitment !== undefined && fitment?.items.length !== 0 && (
                <Tab className='border-r-4 !border-r-[#eff7ff]'>Fitment</Tab>
              )}
              {crossRef !== undefined && crossRef.length !== 0 && <Tab>Cross-references</Tab>}
            </TabList>

            <Text
              bgColor='#FFE5EA'
              fontWeight='700'
              p='50px 60px'
              mt='16px'
              mb='20px'
              color='#FF000F'
              fontSize='18px'
            >
              Depending on the vehicle model, year of manufacture, and trim level, even parts with
              the same name can have different shapes and specifications,
              <br /> which might not fit your car. Please ensure that the part is compatible with
              your own vehicle before making a purchase.
            </Text>

            <TabPanels>
              <TabPanel>
                <Box bg='white' className='w-full rounded-t-lg p-[60px]'>
                  <Heading size='lg' className='mb-9'>
                    Additional information
                  </Heading>
                  <Heading size='sm' className='mb-6'>
                    Vehicle info.
                  </Heading>
                  {brand_name && <Text>Brand : {brand_name} </Text>}
                  {model_name && <Text>Model : {model_name} </Text>}
                  {car_year && <Text>Car Year : {car_year} </Text>}
                  {fuel && <Text>Fuel : {fuel} </Text>}
                  {transmission && <Text>Transmission : {transmission} </Text>}
                  {body_color && <Text>Body Color : {body_color} </Text>}
                  {vin && <Text>VIN : {vin}</Text>}
                  {sku && <Text>SKU : {sku}</Text>}
                  {retail_store && <Text>Location : {retail_store.location}</Text>}
                  <Heading className='pb-9 pt-16' size='lg'>
                    Description
                  </Heading>
                  <pre className='pb-9 font-sans'>{part_description}</pre>
                </Box>
              </TabPanel>
              {fitment?.items.length !== 0 && (
                <TabPanel>
                  <Box bg='white' className='mt-4 w-full rounded-t-lg px-24 pb-10 pt-12'>
                    <Heading size='lg' className='mb-9'>
                      Fitment
                    </Heading>
                    <TableContainer>
                      <Table variant='simple'>
                        <Thead>
                          <Tr>
                            <Th>brand</Th>
                            <Th>Model</Th>
                            <Th>Years</Th>
                            <Th>Series</Th>
                            <Th>Body style</Th>
                            <Th>Doors</Th>
                            <Th>Drive types</Th>
                            <Th>Engine code</Th>
                            <Th>Engine base</Th>
                            <Th>Power output</Th>
                            <Th>Aspiration</Th>
                            <Th>Fuel type</Th>
                            <Th>Build region</Th>
                            <Th>Sold in region</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {fitment &&
                            fitment.items.map((item, index) => (
                              <Tr key={`${item.name}-${index}`}>
                                <Td>{item.make}</Td>
                                <Td>{item.models[0]}</Td>
                                <Td>{item.from_year} </Td>
                                <Td>{item.series_list}</Td>
                                <Td>{item.body_type}</Td>
                                <Td>{item.body_num_doors}</Td>
                                <Td>{item.drive_type}</Td>
                                <Td>{item.engine_base}</Td>
                                <Td>{item.engine_base}</Td>
                                <Td>{item.engine_power_output}</Td>
                                <Td>{item.aspiration}</Td>
                                <Td>{item.fuel_type}</Td>
                                <Td>{item.origin_region}</Td>
                                <Td>
                                  {item.regions.slice(0, 5).map((region, idx, arr) => {
                                    if (item.regions.length > 5 && idx === 4) {
                                      return (
                                        <span key={`${region}_${idx}`}>
                                          {region}
                                          <Popover matchWidth>
                                            <PopoverTrigger>
                                              <span> +{item.regions.length - 5} more</span>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                              <PopoverCloseButton />
                                              <PopoverBody className='whitespace-normal !p-5'>
                                                {item.regions.map(reg => (
                                                  <span>{reg}, </span>
                                                ))}
                                              </PopoverBody>
                                            </PopoverContent>
                                          </Popover>
                                        </span>
                                      );
                                    }
                                    if (arr.length !== 5 && idx === arr.length) {
                                      return <span>{region}</span>;
                                    }
                                    return <span>{region}, </span>;
                                  })}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
              )}
              {crossRef?.length !== 0 && (
                <TabPanel>
                  <Box bg='white' className='mt-4 w-full rounded-t-lg px-24 pb-10 pt-12'>
                    <Heading size='lg' className='mb-9'>
                      Cross-references
                    </Heading>
                    <TableContainer>
                      <Table variant='simple'>
                        <Thead>
                          <Tr>
                            <Th className='!text-base'>Part Number</Th>
                            <Th className='!text-base'>Type</Th>
                            <Th className='!text-base'>Brand</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {crossRef &&
                            crossRef.map(item => (
                              <Tr key={item.part_number}>
                                <Td>{item.part_number}</Td>
                                <Td>{item.is_oem ? 'OEM' : ''} </Td>
                                <Td>{item.brand_name}</Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>

          <Box
            bg='#C2DFF8'
            className='rounded-b-lg px-24 pb-14 pt-11 w-full min-w-[1024px] max-w-[1440px] '
          >
            <Heading size='lg' className='pb-5'>
              Copy link to share
            </Heading>
            <Flex className='gap-8'>
              <Flex bg='white' className='w-full items-center rounded-lg pl-9'>
                <Text color='#808080'>{`${
                  import.meta.env.VITE_API_ENV
                }/product/${productId}`}</Text>
              </Flex>
              <CopyToClipboard text={`${import.meta.env.VITE_API_ENV}/product/${productId}`}>
                <Button size='lg' width='242px' bg='#498BF4' color='white' onClick={onClickCopy}>
                  <Tooltip
                    hasArrow
                    label='Copied!'
                    bg='#E8E8E8'
                    color='#808080'
                    border='1px solid #C7C7C7'
                    w='103px'
                    h='52px'
                    placement='top'
                    mb='20px'
                    borderRadius='8px'
                    isOpen={isCopiedOpen}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    Copy
                  </Tooltip>
                </Button>
              </CopyToClipboard>
            </Flex>
          </Box>
        </div>
      ) : (
        <div className='w-full flex justify-center py-[400px]'>
          <Spinner thickness='4px' size='xl' color='rgb(25 71 141 / 94%)' />
        </div>
      )}
    </Flex>
  );
};

export default ProductDetail;
