import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCartItem, getCartList, getCartListPrice } from '@/api/cart';
import ProductInfo from '@/components/ProductInfo';
import { useAuthStore } from '@/store/useAuthStore';

const Cart = () => {
  const queryClient = useQueryClient();
  const { authUser } = useAuthStore();
  const { data: carts } = useQuery(['carts', authUser], () => getCartList(), {
    enabled: Boolean(authUser),
  });
  const { data: { price } = {} } = useQuery(['price', authUser], () => getCartListPrice(), {
    enabled: Boolean(authUser),
  });

  const { mutate: deleteCart } = useMutation((id: number) => deleteCartItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['carts']);
      queryClient.invalidateQueries(['cartCount']);
    },
  });

  const navigate = useNavigate();

  const onClickPayment = () => {
    if (carts === undefined || carts.length === 0) return;
    navigate('/payment');
  };

  const onClickRedirect = () => {
    if (authUser) {
      navigate('/products');
    } else {
      navigate('/sign-in');
    }
  };

  if (carts === undefined || carts?.length === 0)
    return (
      <Flex className='px-24 py-36 h-full w-full flex justify-center' bgColor='#f5f5f5'>
        <div className={'max-w-[1330px] grow'}>
          <Heading size='lg' className='mb-12'>
            SHOPPING CART
          </Heading>
          <Flex className='text-center flex-col h-full justify-center' color='#498BF4'>
            <Text fontSize='xl'>You have no items in the shopping cart...</Text>
            <Text
              fontSize='lg'
              as='b'
              className='underline cursor-pointer'
              onClick={onClickRedirect}
            >
              {authUser ? 'View Store' : 'Sign in add to cart'}
            </Text>
          </Flex>
        </div>
      </Flex>
    );

  return (
    <Flex className='px-40 py-36 flex-col h-full w-full items-center' bgColor='#f5f5f5'>
      <div className='max-w-[1440px] min-w-[1024px] mx-auto'>
        <Heading size='lg' className='mb-12'>
          SHOPPING CART
        </Heading>
        <Box
          bgColor='#FFE5EA'
          color='#FF000F'
          p='50px 40px'
          fontWeight='700'
          borderRadius='8px 8px 0 0'
          mb='20px'
        >
          Depending on the vehicle model, year of manufacture, and trim level, even parts with the
          same name can have different shapes and specifications, which might not fit your car.
          <br />
          Please ensure that the part is compatible with your own vehicle before making a purchase.
        </Box>
        <Flex className='flex-col'>
          {carts?.map(({ id, product }) => (
            <ProductInfo
              key={id}
              type='cart'
              item={product}
              onClickDeleteCart={() => deleteCart(id)}
            />
          ))}
        </Flex>
        <Box color='red' className='font-medium text-[14px] mt-7 mb-24'>
          <Text>
            Note. The cross boarder delivery from South Korea, the EU import duty 10% and VAT rate
            on your order will be
            <br /> adjusted accordance to EU import duty and EU VAT rules regarding the final
            destination country. <br />
            The parts stock in EU, paid the EU import duty and 21% VAT. <br />
            All prices does not include delivery charges. <br />
            VAT and any duties are paid by the customer when the item(s) arrive.
          </Text>
        </Box>
        <Flex
          bgColor='white'
          className='px-12 py-14 rounded-lg gap-4 flex-col relative border border-solid'
          borderColor='#498BF4'
        >
          <Flex className='items-center gap-[20px] justify-between'>
            <Flex className='items-center gap-[20px] whitespace-nowrap grow'>
              <Text className='font-medium'>Delivery Fee</Text>
              <Divider variant='dottedBlack' flexGrow='1' />
            </Flex>
            <Text>Estimating delivery fee in process</Text>
          </Flex>
          <Flex className='items-center gap-[20px] justify-between'>
            <Flex className='items-center gap-[20px] grow w-full'>
              <Text className='font-medium'>Total</Text>
              <Divider variant='dottedBlack' flexGrow='1' />
            </Flex>
            {price !== 0 && <Text className='whitespace-nowrap'>{price} EUR</Text>}
          </Flex>
          <Flex className='items-center justify-between gap-[20px]'>
            <Flex className='items-center gap-[20px] grow w-full'>
              <Text className='whitespace-nowrap' fontSize='xl' as='b'>
                Full price
              </Text>
              <Divider variant='dottedBlack' />
            </Flex>
            <Text as='b' color='#498BF4'>
              {price !== 0 && <Text className='whitespace-nowrap'>{price} EUR + Delivery Fee</Text>}
            </Text>
          </Flex>
          <Flex className='flex-col items-end mt-6'>
            <Divider size='xl' bg='#000' style={{ border: '1px solid black', marginBottom: 12 }} />
          </Flex>
        </Flex>
        <Button
          className='relative left-3/4 w-1/4 mt-8'
          variant={carts?.length === 0 || carts === undefined ? 'disabled' : 'primary'}
          onClick={onClickPayment}
        >
          Go to Delivery
        </Button>
      </div>
    </Flex>
  );
};

export default Cart;
