/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Textarea, TextareaProps } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { BaseProps, FormControl } from '@/components/FormControl/FormControl';

export interface TextareaControlProps extends BaseProps {
  /**
   * Chakra TextareaProps
   */
  textareaProps?: TextareaProps;
}

export const ControlledTextArea: FC<TextareaControlProps> = (props: TextareaControlProps) => {
  const { name, control, textareaProps, label, ...rest } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl name={name} control={control} label={label} {...rest}>
      <Textarea
        {...field}
        id={name}
        isDisabled={isSubmitting}
        {...textareaProps}
        className='!h-[200px] font-bold !bg-white'
      />
    </FormControl>
  );
};

export default ControlledTextArea;
