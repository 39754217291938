/* eslint-disable react/no-array-index-key */
import { Heading, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getOrderList } from '@/api/order';
import OrderListItem from '@/components/OrderListItem';
import { IOrder } from '@/types/cart.ts';
import { useAuthStore } from '@/store/useAuthStore';
import Pagination from '../Pagination';
import { useState } from 'react';
import Spinners from '../Spinner';

const MyOrder = () => {
  const [offset, setOffset] = useState(0);
  const { authUser } = useAuthStore();
  const { data, isSuccess, isLoading } = useQuery(
    ['orders', authUser, offset],
    () => getOrderList(offset),
    {},
  );

  return (
    <div>
      <Heading size='lg' className='mb-8'>
        MY ORDERS
      </Heading>
      {isSuccess &&
        (data?.items?.length ? (
          <>
            {data.items.map((item, idx) => (
              <OrderListItem order={item as IOrder} key={`order_${idx}`} />
            ))}
            <Pagination
              total={data?.total}
              offset={offset}
              onChangeOffset={offset => setOffset(offset)}
              page_size={20}
            />
          </>
        ) : (
          <Text className='font-normal' color='#498BF4' fontSize='20px' textAlign='center'>
            No order data is available. Why won't you take a look at our catalogs?
          </Text>
        ))}
      {isLoading && <Spinners />}
    </div>
  );
};

export default MyOrder;
