import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '@chakra-ui/react';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { LoginStatus, useAuthStore } from '@/store/useAuthStore';
import { getUserProfile } from '@/api/auth';
import Spinner from '../Spinner';

interface ILayout {
  children: ReactNode;
  withAuth: boolean;
}

const Layout = ({ children, withAuth }: ILayout) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loginStatus, setAuthUser, setLoginStatus } = useAuthStore();

  const checkAuth = async () => {
    try {
      const user = await getUserProfile();
      setLoginStatus(LoginStatus.login);
      setAuthUser(user);
    } catch (err) {
      setLoginStatus(LoginStatus.logout);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (loginStatus === LoginStatus.logout && withAuth) {
      navigate('/sign-in');
    }
  }, [withAuth, loginStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Grid
      h='100vh'
      templateAreas={`"header header"
						"main main"
						"footer footer"`}
      gridTemplateRows='160px 1fr 690px'
    >
      <GridItem area='header' className='h-full'>
        <Header />
      </GridItem>
      <GridItem area='main'>{loginStatus === 'loading' ? <Spinner /> : children}</GridItem>
      <GridItem area='footer'>
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default Layout;
