/* eslint-disable camelcase */
import { Box, Flex, HStack, Heading, Image, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getPartCrossReference } from '@/api/part';
import { IPartItem } from '@/types/parts';

interface Props {
  item: IPartItem;
}

const CartItem = ({ item }: Props) => {
  const { part_number, quality, images, price, brand_name, model_name, car_year, sku } = item;
  const { data: crossRefs } = useQuery(
    ['cross_ref', item.id],
    () => getPartCrossReference(part_number ?? ''),
    {
      enabled: !!part_number,
      select: data => data.items,
    },
  );
  return (
    <Flex bg='white' className='p-12 rounded-lg justify-between mb-12'>
      <HStack>
        <Box w='236px' h='166px'>
          <Image className='w-full h-full rounded-lg' src={images && images[0]?.image_url} />
        </Box>
        <Flex className='flex-col justify-between ml-8 h-full'>
          <Heading size='md'>Original Part Number: {part_number}</Heading>
          <Flex className='flex-col'>
            {crossRefs && crossRefs.length !== 0 && (
              <Flex className='!inline-flex'>
                <Text as='b'>
                  Cross-References:{' '}
                  {crossRefs
                    .slice(0, 3)
                    .map(({ part_number: partNumber }) => partNumber)
                    .join(', ')}
                  {crossRefs.length > 3 && <span>...more</span>}
                </Text>
              </Flex>
            )}
            {brand_name && <Text as='b'>Brand : {brand_name}</Text>}
            {model_name && <Text as='b'>Model : {model_name}</Text>}
            {car_year && <Text as='b'>Car Year: {car_year}</Text>}
            {sku && <Text as='b'>SKU : {sku} </Text>}
            {quality && <Text as='b'>Quality : {quality} </Text>}
          </Flex>
        </Flex>
      </HStack>
      <Flex className='flex-col text-end'>
        <Text>Price</Text>
        <Heading size='md'>{Number(price)} EUR</Heading>
      </Flex>
    </Flex>
  );
};

export default CartItem;
