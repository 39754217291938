import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { IOrder } from '@/types/cart';
import { useNavigate } from 'react-router-dom';

interface Props {
  order: IOrder;
}

const OrderListItem = ({ order }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {order?.order_items?.map(item => (
        <Flex key={item.id} className='p-7 py-12 rounded-lg bg-white w-full mb-5' gap='48px'>
          <Box h='125px' w='186.5px' minW='186.5px'>
            {item?.product?.images && (
              <Image
                className='rounded-lg !w-full h-full'
                src={item.product.images?.[0]?.image_url}
              />
            )}
          </Box>
          <Flex className='flex-col rounded-lg bg-white flex-1 font-bold' gap='20px'>
            <Heading size='md'>
              {item?.product?.part_name}
              {order?.order_items?.length > 1 && ` and ${order?.order_items?.length - 1}  more`}
            </Heading>
            <Box>
              <Text>
                Total:{' '}
                <span className='font-normal'>
                  {Number(order?.delivery_fee) + Number(order?.total_price)} EUR
                </span>
              </Text>
              <Flex gap={60} whiteSpace='nowrap'>
                <Text>
                  Order Number: <span className='font-normal'> {order?.id}</span>
                </Text>
                <Text>
                  Order Placed:
                  <span className='font-normal'>
                    {order?.create_date && dayjs(order?.create_date).format(' DD MMMM, YYYY')}
                  </span>
                </Text>
              </Flex>
            </Box>
            <Button
              colorScheme='blue'
              size='md'
              w='fit-content'
              className='rounded-lg text-white self-end'
              onClick={() => navigate(`/my-page/orders/${order?.id}`)}
            >
              Order Details
            </Button>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default OrderListItem;
